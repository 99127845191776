import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return <div {...props}></div>;
  }
}

export default class Responsive extends Component {
 
  render() {
    const Services1=
    [ 
      {index:1,title:'Impianti Dentali', imgsrc:'./img/team/img1.webp',link:'Implanti'},
      {index:2,title:'All on 4',  imgsrc:'./img/team/img3.webp',link:'Allon4'},
      {index:3,title:'All on 6',  imgsrc:'./img/team/img4.webp',link:'Allon6'},       
    ]
  
    const Services2=
    [ 
      {index:4,title:'Invisalign', imgsrc:'./img/team/img8.webp',link:'Invisalign'},  
      {index:5,title:'Protesi dentali mobili', imgsrc:'./img/team/img7.webp',link:'Protesidentali'},
      {index:6,title:'Protesi dentali fissi', imgsrc:'./img/team/img9.webp',link:'Protesifissi'},
    
    ]

  const Services3=
  [ 
    {index:7,title:'Corone in zirconio',  imgsrc:'./img/team/img5.webp',link:'Coronezirconio'},
    {index:8,title:'Corone in ceramica',  imgsrc:'./img/team/img6.webp',link:'Coroneceramica'},
    {index:9,title:'Veneers E-max', imgsrc:'./img/team/img2.webp',link:'Veneers'},
        
  ]
  
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="slick slickteam">
        <Slider {...settings}>
          {Services1.map(service => (
        <Link to={service.link}>
           <CustomSlide
              className="itm"
              key={service.index}
              index={service.index}
            >
              <div className="bg">
                <img
                  src={service.imgsrc}
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              <div className="desc">
                <div className="name">{service.title}</div>
              </div>
            </CustomSlide>
            </Link>
          ))}
          
        </Slider>
<br></br>
<br></br>
<br></br>
<br></br>
        <Slider {...settings}>
          {Services2.map(service => (
          <Link to={service.link}>
           <CustomSlide
              className="itm"
              key={service.index}
              index={service.index}
            >
              <div className="bg">
                <img
                  src={service.imgsrc}
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              <div className="desc">
                <div className="name">{service.title}</div>
              </div>
            </CustomSlide>
            </Link>
          ))}
          
        </Slider>

        <br></br>
<br></br>
<br></br>
        <Slider {...settings}>
          {Services3.map(service => (
        <Link to={service.link}>
           <CustomSlide
              className="itm"
              key={service.index}
              index={service.index}
            >
              <div className="bg">
                <img
                  src={service.imgsrc}
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              <div className="desc">
                <div className="name">{service.title}</div>
              </div>
            </CustomSlide>
          </Link> 
          ))}
          
        </Slider>
      </div>
    );
  }
}
