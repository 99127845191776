import React, { useState, useEffect } from "react";
import {
  Wrapper,
  Container,
  Body,
  Page,
  LinkTag,
  SocialContainer,
  Overlaybg
} from "../styles/Navigation.styles";
import { withRouter } from "react-router";
import NavButton from "./NavButton";

const NavigationMenu = ({ history, hasBackground, setBackground }) => {
  const [isOn, setState] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [linking, setLink] = useState("");

  useEffect(() => {
    !!linking &&
      setTimeout(() => {
        switch (linking) {
          case "home":
            history.push("/");
            setState(false);
            setLink("");
            break;
          case "about":
            history.push("/about");
            setState(false);
            setLink("");
            break;
          case "servizi":
            history.push("/servizi");
            setState(false);
            setLink("");
            break;
            case "news":
              history.push("/news");
              setState(false);
              setLink("");
              break;
              case "gallery":
              history.push("/gallery");
              setState(false);
              setLink("");
              break;
              case "case2":
              history.push("/case2");
              setState(false);
              setLink("");
              break;
              case "case3":
              history.push("/case3");
              setState(false);
              setLink("");
              break;
        
            case "albania":
              history.push("/albania");
              setState(false);
              setLink("");
              break;
              case "testimonianze":
                history.push("/testimonianze");
                setState(false);
                setLink("");
                break;
          case "contact":
            history.push("/contact");
            setState(false);
            setLink("");
            break;
          default:
            setLink("");
        }
        setBackground(false);
        window.scrollTo(0, 0);
      }, 0);
  }, [linking, history, setBackground]);

  useEffect(() => {
    shouldAnimate &&
      !isOn &&
      setTimeout(() => {
        setShouldAnimate(false);
      }, 0);
  }, [shouldAnimate, isOn]);

  const closeHandler = () => {
    setShouldAnimate(true);
    setState(!isOn);
  };

  const setLinkHandler = text => {
    setShouldAnimate(true);
    setLink(text);
  };

  useEffect(() => {
    const header = document.getElementById("header");
    const totop = document.getElementById("scroll-to-top")
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
    if (window.pageYOffset > sticky + 0) {
      header.classList.add("sticky");
      totop.classList.add("show");
    } else {
      header.classList.remove("sticky");
      totop.classList.remove("show");
    } 
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
    }, []);

  return (
    <header>
      <div id='header'>
      <div className='logo'>
        <img
          src="./img/logowhite.png"
          className="img-fluid"
          alt="#"
        />
        <span className='callus'>
          Contattaci: (+355) 69 40 48 535 
        </span>
      </div>
      </div>
      <Wrapper open={isOn} shouldAnimate={shouldAnimate}>
        <Overlaybg open={isOn} shouldAnimate={shouldAnimate} onClick={closeHandler}/>
        <Container
          open={isOn}
          onClick={closeHandler}
          hasBackground={hasBackground}>
          <NavButton open={isOn} />
        </Container>
        <Body className='midwrpr' open={isOn} shouldAnimate={shouldAnimate}>
          <div className='conPage'>
              <Page className='mainBtn' variant="home" onClick={() => setLinkHandler("home")}>
                  <LinkTag>Home</LinkTag>
              </Page>
              <Page className='mainBtn' variant="about" onClick={() => setLinkHandler("about")} >
                  <LinkTag>Chi Siamo</LinkTag>
              </Page>
              
              <Page className='mainBtn' variant="albania" onClick={() => setLinkHandler("albania")} >
                  <LinkTag>Perche l'Albania</LinkTag>
              </Page>
                
              <Page className='mainBtn' variant="servizi" onClick={() => setLinkHandler("servizi")} >
                  <LinkTag>Servizi</LinkTag>
              </Page>

              <Page className='mainBtn' variant="testimonianze" onClick={() => setLinkHandler("testimonianze")} >
                  <LinkTag>Testimonianze</LinkTag>
              </Page>
          
              <Page className='mainBtn' variant="albania" onClick={() => setLinkHandler("gallery")} >
                  <LinkTag>Galleria</LinkTag>
              </Page>
              <Page className='mainBtn' variant="contact" onClick={() => setLinkHandler("contact")}>
                  <LinkTag>Contattaci</LinkTag>
              </Page>
          </div>

          <div className='info'>
            <span>(+355) 69 40 48 535 </span>
            <span className='link'>info@sorridial.com</span>
            <span>Rruga Hasan Alla, Tirana, 1060 Albania</span>
          </div>

        </Body>
        <SocialContainer className='soc-icon' open={isOn}>
            <span>Seguici:</span>
            <a href='https://www.facebook.com/dentalturismoAlbania/' className='socicon'><i className="fa fa-facebook-f"></i></a>
            {/* <span className='socicon'><i className="fa fa-linkedin"></i></span> */}
            {/* <span className='socicon'><i className="fa fa-twitter"></i></span> */}
            <a className='socicon'><i className="fa  fa-instagram"></i></a>
        </SocialContainer>
      </Wrapper>
    </header>
  );
};

export default withRouter(NavigationMenu);
