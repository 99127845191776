import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
  
} from "../styles/Case.styles";
import GalleryMasonry from "../components/Gallery/GalleryMasonry";
import ImageWithCaption from "../components/Gallery/GalleryMasonry.js";
import "../components/Gallery/styles.css";
import { photos } from "../components/Gallery/Photos.js";
import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Accomplish from '../components/Accomplish';
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";


const imageRenderer = ({ index, left, top, key, photo }) => (
  <ImageWithCaption
    key={key}
    margin={"2px"}
    index={index}
    photo={photo}
    left={left}
    top={top}
  />
);


export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Case extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/work");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {

    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <Reveal effect="fadeIn">
      <section className='header__baner jumbotron imgtop'>
          <h3> LA ESPERIENZA DEI CLIENTI</h3>
        	<img src="./img/testimonials.webp" className="img-fluid" alt="Albania" />
        </section>
       </Reveal>
        

        <section className='container-fluid' id='detailproject'>
          <div className='row'>
            <div className='col-md-12'>
       
            <div className="row d-flex align-items-center mb-5">
          
          <div className='col-md-6'>  
            <iframe width="100%" height="315" 
            src="https://www.youtube.com/embed/iZqnJHMLeIA" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen />
          </div>

          <div className="col-md-6">
          <Reveal effect="fadeIn">
	        <div className='row m-2-hor'>
	        	<div className='col-md-12'>
	        	
              <h4>GIOVANNI ALFIERI</h4>
              Buona qualita prezzo, personale cordiale e professionale, che comprende le necessita del paziente ascoltandolo e venendole incontro a tutte le richieste. Attenti ai dettagli ed esaurienti nel spiegare cosa ce da fare e cosa e' stato fatto.
	        	</div>
	        </div>
	        </Reveal>
          </div>

        </div>

        <div className="row d-flex align-items-center mb-5">

          <div className='col-md-6'>    
            <iframe width="100%" height="315" 
            src="https://www.youtube.com/embed/9XPz_otA7J0" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen/>
          </div>

          <div className="col-md-6">
          <Reveal effect="fadeIn">
	        <div className='row m-2-hor'>
	        	<div className='col-md-12'>
	        	
              <h4>ANELYA BULGAROVA</h4>
              Sono stato a Tirana e consiglio SorridiAL a tutti, per la loro gentilezza, cortesia, professionalita'e prezzi ottimi. Io ho tolto 11 denti, ho messo l'intera arcata superiore, impianti e corone, e tutto questo in meno di 5 giorni. Ringrazio tutti, dal dentista al suo gentilissimo staff che e' statto molto gentile con me, e che con molta simpatia e pazienza mi hanno dato pieno supporto nei momenti di bisogno. Veramente bravi. Bravi, bravi, bravi. Complimenti e grazie di cuore per tutto. P.S un ringraziamento anche alle addete telefonice, le sales agents, le quali mi hanno spiegato il tutto nei minimi particolari prima di arrivare. Grazie.
	        		
	        	</div>
	        </div>
	        </Reveal>
          </div>

        </div>

         <div className="row d-flex align-items-center mb-5">

          <div className='col-md-6'>
            <iframe width="100%" height="315" 
            src="https://www.youtube.com/embed/k7bQyjZaWUg" title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen/>
          </div>

          <div className="col-md-6">
          <Reveal effect="fadeIn">
	        <div className='row m-2-hor'>
	        	<div className='col-md-12'>
	        	
              <h4>RICCARDO HOLLER </h4>
              Sono rimasto molto contento dell'accoglienza e professionalita dello staff.  All'inizio non mi fidavo, ma ora lo consiglio a tutti.
	        		
	        	</div>
	        </div>
	        </Reveal>
          </div>
      
       </div>

       
       <div className="row d-flex align-items-center mb-5">

<div className='col-md-6'>
  <iframe width="100%" height="315" 
  src="https://www.youtube.com/embed/ykX6Z0uKGOI" title="YouTube video player" frameborder="0" 
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
  allowfullscreen/>
</div>

<div className="col-md-6">
<Reveal effect="fadeIn">
<div className='row m-2-hor'>
  <div className='col-md-12'>
  
    <h4>BENITO ARERVO </h4>
    Quando mi guardo allo specchio mi si abbagliano gli occhi.
Quando sono arrivato a Tirana, ero molto scettico, pero ho dovuto ricredermi subito, perche ho trovato gia dall'aeroporto una forte gentilezza, mi sono trovato come essere a casa, persone molto gentili, dottore alla mano, molto professionale. Consigliero' anche ad altra gente a venire a curarsi con SorridiAl. Sono rimasto molto contento.
Consiglio a tutti SorridiAl perche sono professionali e gentili. 
    
  </div>
</div>
</Reveal>
</div>

</div>
            
            
          
            
         
            </div>

          </div>
        </section>         
     

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Case);
