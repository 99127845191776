export const photos = [
    {
      src: "./img/works/work1.webp",
      width: 1,
      height: 1,
      caption: "some trees"
    },
    {
      src: "./img/works/work2.webp",
      width: 1,
      height: 1,
      caption: "blurring pic"
    },
    {
      src: "./img/works/work3.webp",
      width: 1,
      height: 1,
      caption: "blurring pic"
    },
    {
      src: "./img/works/work4.webp",
      width: 4,
      height: 3,
      caption: "blurring pic"
    },
    {
      src: "./img/works/work5.webp",
      width: 4,
      height: 3,
      caption: "blurring pic"
    },
    {
      src: "./img/works/work6.webp",
      width: 4,
      height: 3,
      caption: "blurring pic"
    },
    {
      src: "./img/works/work7.webp",
      width: 1,
      height: 1,
      caption: "blurring pic"
    },
    {
      src: "./img/works/work8.webp",
      width: 1,
      height: 1,
      caption: "blurring pic"
    },
    {
      src: "./img/works/work9.webp",
      width: 1,
      height: 1,
      caption: "blurring pic"
    },
    {
      src: "./img/works/work10.webp",
      width: 3,
      height: 4,
      caption: "deciduous"
    },
    {
      src: "./img/works/work11.webp",
      width: 3,
      height: 4,
      caption: "foggy"
    },
    {
      src: "./img/works/work12.webp",
      width: 3,
      height: 4,
      caption: "oak leaves"
    },
    {
      src: "./img/works/work13.webp",
      width: 1,
      height: 1,
      caption: "even foggier"
    },
    {
      src: "./img/works/work14.webp",
      width: 1,
      height: 1,
      caption: "is this a hemlock?"
    },
    {
      src: "./img/works/work15.webp",
      width: 1,
      height: 1,
      caption: "wat"
    },
    {
      src: "./img/works/work16.webp",
      width: 1,
      height: 1,
      caption: "autumn"
    },
    {
      src: "./img/works/work17.webp",
      width: 1,
      height: 1,
      caption: "autumn"
    },
    {
      src: "./img/works/work18.webp",
      width: 1,
      height: 1,
      caption: "autumn"
    }
  ];
  