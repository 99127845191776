import React, {useState,Component} from "react";
import styles from './contactform.module.css';
import axios from "axios";


class ContactForm extends React.Component {
  
  state = {
    fname: '',
    lname: '',
    email: '',
    message: '',
    filename:'',
    file:'',
    phonenumber:'',
    mailSent: false,
    error: null
  }


  handleFormSubmit = e => {
    e.preventDefault();
    axios({
      method: 'post',
      url: 'api/contact/index.php',
      headers: { 'content-type': 'application/json' },
      data: this.state
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent
        })
      })
      .catch(error => this.setState({ error: error.message }));
  };


  render() {
    return (
      <div className={styles.formdiv}>
      <form action="#" enctype="multipart/form-data">

      <div className={styles.inputgroup}>

  <label className={styles.formlabel}>Nome</label>
  <input className={styles.inputform} type="text" id="fname" name="firstname" placeholder="Nome"
    value={this.state.fname}
    onChange={e => this.setState({ fname: e.target.value })}
  />

</div>

<div className={styles.inputgroup}>
<label className={styles.formlabel}>Cognome</label>
  <input  className={styles.inputform} type="text" id="lname" name="lastname" placeholder="Cognome"
    value={this.state.lname}
    onChange={e => this.setState({ lname: e.target.value })}
  />
</div>

<div className={styles.inputgroup}>

<label className={styles.formlabel}>Email</label>
  <input className={styles.inputform} type="email" id="email" name="email" placeholder="Email"
    value={this.state.email}
    onChange={e => this.setState({ email: e.target.value })}
  />

</div>

<div className={styles.inputgroup}>
<label className={styles.formlabel}>Numero Telefono</label>
  <input className={styles.inputform} type="text" id="phonenumber" name="phonenumber" placeholder="Numero Telefono"
    value={this.state.phonenumber}
    onChange={e => this.setState({ phonenumber: e.target.value })}
  />
</div>


<div className={styles.inputgroup}>
  <label className={styles.formlabel}>Messaggio</label>
  <textarea id="message" className={styles.textarea} name="message" placeholder="Scrivi Qualcosa.."
    onChange={e => this.setState({ message: e.target.value })}
    value={this.state.message}
  ></textarea>

</div>

{/* <div>
<label className={styles.formlabel}>Carica Panoramex</label>
  <input className={styles.formselect} onChange={e => this.setState({file : e.target.files[0]})} type="file" name="file" />
  <input type="file" name="file_attach" class="input-field" />
</div> */}

  <input className={styles.formbutton} type="submit" onClick={e => this.handleFormSubmit(e)} value="INVIA RICHIESTA" />
      </form>
      </div>
    );
  }
}


export default ContactForm;
