
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./styles/animated-on3step.css";
import "./styles/main.scss";

import Preloader from "./components/Preloader";
import NavigationMenu from "./components/Nav";
import ScrollToTopBtn from './components/ScrollToTop';
import Home from "./pages/Home";
import About from "./pages/About";
import Work from "./pages/works";
import Albania from "./pages/Albania";
import Contact from "./pages/Contact";
import Servizi from "./pages/Servizi";
import Gallery from "./pages/Gallery";
import Case2 from "./pages/detailCase2";
import Case3 from "./pages/detailCase3";
import Implanti from "./pages/Implanti";
import Coronezirconio from "./pages/Coronezirconio";
import Coroneceramica from "./pages/Coroneceramica";
import Allon4 from "./pages/Allon4";
import Allon6 from "./pages/Allon6";
import Invisalign from "./pages/Invisalign";
import Protesidentali from "./pages/Protesidentali";
import Protesifissi from "./pages/Protesifissi";
import Veneers from "./pages/Veneers";
import Testimonianze from "./pages/Testimonianze";
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
  const [loading, setLoading] = useState(true);
  const [navBackground, setNavBack] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [loading]);

  return (
    <Router>
      {loading ? (
        <Preloader />
      ) : (
        <div className="App">
          <NavigationMenu
            firstTime={loading}
            hasBackground={navBackground}
            setBackground={state => setNavBack(state)}
          />
          <Route path="/" exact component={Home} />
          <Route path="/About" exact component={About} />
          <Route path="/Servizi" exact component={Servizi} />
          <Route path="/Case2" exact component={Case2} />
          <Route path="/Case3" exact component={Case3} />
          <Route path="/Invisalign" exact component={Invisalign} />
          <Route path="/Protesidentali" exact component={Protesidentali} />
          <Route path="/Protesifissi" exact component={Protesifissi} />
          <Route path="/Allon4" exact component={Allon4} />
          <Route path="/Allon6" exact component={Allon6} />
          <Route path="/Coronezirconio" exact component={Coronezirconio} />
          <Route path="/Coroneceramica" exact component={Coroneceramica} />
          <Route path="/Albania" exact component={Albania} />
          <Route path="/Contact" exact component={Contact} />
          <Route path="/Veneers" exact component={Veneers} />
          <Route path="/Testimonianze" exact component={Testimonianze} />
          
          <Route
            path="/Gallery"
            component={() => (
              <Gallery setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase2"
            component={() => (
              <Case2 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase3"
            component={() => (
              <Case3 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/implanti" exact
            component={() => (
              <Implanti setNavBackground={state => setNavBack(state)} />
            )}
          />
          
          {/* <TawkMessengerReact
                propertyId="property_id"
                widgetId="default"/>    
 */}


        <ScrollToTopBtn />  
        </div>
      )}
    </Router>
  );
}

export default App;