import React from "react";
import { withRouter } from "react-router-dom";
import Reveal from 'react-reveal/Reveal';
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";
import Aboutus from '../components/Aboutus';
import Carouselclient from '../components/Carouselclient';
import Carouselclientb from '../components/Carouselclientb';
import Carouselteam from '../components/Carouselteam';
import Accomplish from '../components/Accomplish';
import Carouselprojects from '../components/Carouselprojects';
import Footer from '../components/Footer';

import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
import { Link } from 'react-router-dom';

const bstyle ={
  border: '1px solid #5aa6a6',
  background: '#5aa6a6',
  fontweight: '600',
  
    fontsize: '11pt',
    letterspacing: '1px',
    borderRadius: '5px',
    padding: '0 44px',
    height: '45px',
    marginTop: '25px',
    marginLeft: 'auto',
    marginRight: '13px',
    color: '#fff'
}


const About = ({ history }) => {

  return (
    <div>
      <Efect/>
      <Efect1/>
      <Efect2/>


      <Reveal effect="fadeIn">
        <section className='header__baner jumbotron imgtop'>
          <h3>CHI SIAMO</h3>
        	<img src="./img/about1.webp" className="img-fluid" alt="Sorridial" />
        </section>
       </Reveal>


      <Reveal effect="fadeIn">
        <section className='container-fluid pb-0'>
	        <div className='row m-2-hor'>
	        	<div className='col-md-4'>
	        		<h1>COME E NATO</h1>
	        	</div>
	        	<div className='col-md-8'>
	        		<div className='content'>
              Secondo Balkan Insight, i turisti di tutto il mondo hanno valutato l'Albania come la migliore 
              destinazione in Europa con viste incredibili, prezzi molto bassi e ottimi servizi. Quindi, nel 
              corso degli anni, sono centinaia di migliaia di stranieri che hanno colto l'occasione per fare 
              le cure dentistiche mentre erano in vacanza.
	        		</div>
	        	</div>
	        </div>
	    </section>
	   </Reveal>

	  <Reveal effect="fadeInUp">
        <Aboutus/>
      </Reveal>


      <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "url('./img/tirana.webp')" }}>
              <div className='herocaption'>
               
                <ClientTag>Tirana</ClientTag>
                <Title>Dove ci troviamo?</Title>
                <TechniqueTitle>
                Distiamo di soli 90 minuti dall'Italia, compresi i trasferimenti. Tirana è la capitale e la città 
                più grande dell'Albania. Si trova al centro del paese, racchiuso da montagne e colline con Dajti 
                che sorge a est e una leggera valle a nord-ovest che si affaccia sul mare Adriatico in lontananza. 
                Tirana, è nota per la sua colorata architettura in stile italiano. Cogli l'occasione per visitare 
                una nuova destinazione turistica e risolvere i tuoi problemi dentali.
                </TechniqueTitle>
              </div>
          </Hero>
        </Reveal>

        <Reveal effect="fadeIn">
        <section className='container-fluid pb-0'>
	        <div className='row m-2-hor'>
	        	<div className='col-md-4'>
	        		<h1>IL NOSTRO OBBIETTIVO</h1>
	        	</div>
	        	<div className='col-md-8'>
	        		<div className='content'>
              
              Il nostro obiettivo è costruire una relazione a lungo termine con te in un ambiente rilassato e amichevole. 
              Ci impegniamo ad aiutarti a preservare i tuoi denti naturali per tutta la vita e a mantenere la tua salute orale a un livello ottimale. <br></br> <br></br>
              Il nostro staff e' qualificato e specializzato in diversi ambiti dentistici, come l'implantologia, la chirurgia orale e tanto altro, dando cosi' la possibilita' di avere tutte le soluzioni in un unico posto. <br></br> <br></br>La nostra visione e ben piu' ampia, cercando di venire da voi in un futuro vicino anche con altri rami del turismo, combinando cosi' il turismo classico con il turismo medico - estetico - terapeutico.
	        		</div>
	        	</div>
	        </div>
	    </section>
	   </Reveal>
      <br></br>
     <br></br>

	<section style={{paddingBottom:"10px"}} className='container-fluid bggray'>

    <div className="row m-2-hor">
    <h1 className='mb-5'>
        COSA TI  OFFRIAMO: 
        </h1>
    </div>
	  <div className='row m-2-hor'>
       
	    <div className='col-md-6'>
    <ul className="left-list">
      <li>Informazione dettagliata gratuita.</li>
      <li>Panoramica dentale gratuita.</li>
      <li>Trasporto gratuito dal / all' aeroporto.</li>
      <li>Consulenza e preventivo gratuito.</li>
      <li>Supporto logistico per pazienti.</li>
      <li>Alloggio gratuito presso le migliori strutture alberghiere vicino al centro.</li>
      <li>Possibilità di iniziare subito il lavoro concordato.</li>
      <li>Garanzia a vita su qualsiasi trattamento odontoiatrico.</li>
      <li>Esclusa IVA (20%) per tutti i tipi di cure odontoiatriche.</li>
      <li>Flessibilità dei dentisti a seconda delle richieste del paziente.</li>
      
    </ul>
    <Link to={'/contact'}>
    <button className="scopributton" style={bstyle}>
                    
                    
                    Contattaci
                   
                    
    </button>
    </Link>
	      
	    </div>

      <div className="col-md-6 about__img">
        <img src="./img/collage.jpg" />
      </div>
	  </div>
	</section>



 

      <Footer />

    </div>
    
  );
};

export default withRouter(About);
