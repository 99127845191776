import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoPlay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className='slick slicknews'>
        <Slider {...settings}>

          <CustomSlide className='itm' index={1}>
            <div className='bg'>
              <img
                src="./img/news/img1.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className='desc'>
              {/* <div className='date'> Sept 08, 2020</div> */}
              <div className='name'>Igiene orale – Come Migliorarlo</div>
              <div className='content'>
              Una delle prime routine che ci viene spiegata fin da piccoli è quella dell'igiene orale...
              </div>
            </div>
            <div className='icon'>
               <span onClick={()=> window.open("https://www.sunstargum.com/it/blog/migliore-routine-di-igiene-orale.html", "_self")}>
               Leggi di più

              </span>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={2}>
            <div className='bg'>
              <img
                src="./img/news/img2.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className='desc'>
              {/* <div className='date'> Sept 08, 2020</div> */}
              <div className='name'>Cosa sono le infezioni dentali</div>
              <div className='content'>
           
              L'infezione dentale è un'invasione di patogeni (in genere, batteri) che penetrano nel dente ed ivi si...

              </div>
            </div>
            <div className='icon'>
               <span onClick={()=> window.open("https://www.my-personaltrainer.it/benessere/infezione-dente.html", "_self")}>
               Leggi di più
              </span>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={3}>
            <div className='bg'>
              <img
                src="./img/news/img3.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
           <div className='desc'>
              {/* <div className='date'> Sept 08, 2020</div> */}
              <div className='name'>Come superare l'ansia dentale</div>
              <div className='content'>
              L'ansia dentale è una sensazione costante che qualcosa andrà storto durante il trattamento dentale...

              </div>
            </div>
            <div className='icon'>
            <span onClick={()=> window.open("https://www.wikihow.it/Superare-la-Paura-del-Dentista#", "_self")}>
               Leggi di più
              </span>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={4}>
            <div className='bg'>
              <img
                src="./img/news/img4.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className='desc'>
              {/* <div className='date'> Sept 08, 2020</div> */}
              <div className='name'>Cosa fa il fluoro...</div>
              <div className='content'>
              Il fluoro è un minerale che si trova nel fluoro che è il tredicesimo elemento più abbondante nella crosta terrestre...
              </div>
            </div>
            <div className='icon'>
               <span onClick={()=> window.open("https://www.sunstargum.com/it/salute-gum/come-fare/cos-e-il-fluoro.html", "_self")}>
               Leggi di più
              </span>
            </div>
          </CustomSlide>

        </Slider>
      </div>
    );
  }
}
