import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Protesifissi extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/news");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.3)),url('./img/news/implants/dental-implant.webp')" }}>
              <div className='herocaption'>
               
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 pl-md-0'>
                      <ClientTag className='date'></ClientTag>
                      <Title>Definizione</Title>
                      <TechniqueTitle>
                      La protesi dentale fissa consente di sostituire i denti mancanti o fortemente danneggiati in maniera permanente, garantendo al paziente un sorriso impeccabile, come quello assicurato dai denti naturali.
La protesi dentaria fissa si compone di capsule e corone formate da materiali come lo zirconio e la ceramica in grado di assicurare un risultato ottimale anche in termini di resistenza e biocompatibilità, oltre che estetici. Quest’ultime vengono ancorate dal dentista ai cosiddetti “elementi pilastro”, rappresentati da impianti e denti naturali.




                      </TechniqueTitle>
                    </div>
                  </div>
                </div>
              </div>
          </Hero>
          </Reveal>

        <section className='container' id='detailproject'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='content'>
                
              <h3 className='my-5'>Come avviene la realizzazione della protesi fissa?
              </h3>
                
                <p>Una volta ristabilita l’integrità dei siti post estrattivi, l’odontoiatra sotto la supervisione del dentista, provvede a prendere l’impronta inserendo un cucchiaio porta-impronte di metallo o di silicone/plastica anatomico a forma di arcata o semi-arcata a seconda dell’impronta da rilevare contenente una speciale pasta morbida nella bocca del paziente.
I denti di quest’ultimo affonderanno nella pasta fin quando essa non sarà indurita del tutto (in genere l’operazione dura 2-3 minuti). Una volta terminata la presa dell’impronta, il dentista estrarrà il cucchiaio dalla bocca del paziente con estrema delicatezza, in modo da evitare possibili alterazioni al calco ottenuto.
 <br/><br/>L’impronta verrà poi inviata al laboratorio odontoiatrico insieme alle relative informazioni di forma e colore. Quest’ultimo entro pochi giorni la restituirà al dentista, il quale, in collaborazione con l’odontoiatra effettuerà tutte le prove necessarie e le doverose modifiche prima di fissarla nella bocca del paziente fornendogli tutte le indicazioni di cui necessita per la corretta manutenzione della stessa (pulizia, utilizzo, accorgimenti da adottare a tavola, etc.).
 </p>
                
                


                 <h4 className='my-5'>  	Le protesi fisse si distinguono in 3 tipologie, ognuna caratterizzata da una precisa funzione:
                </h4> 

<ul>
<li>
	<b>protesi fissa di sostituzione:</b> la protesi dentale fissa di sostituzione dà la possibilità di sostituire in toto con componenti specifici (come ad esempio gli elementi intermedi di protesi a ponte) i denti naturali.
</li>
<li>
<b>protesi fissa di ricostruzione:</b> questo tipo di protesi fissa permette di ricostruire le parti anatomiche del dente estratto al fine di preservarlo dalla totale distruzione. Fra le protesi fisse di sostituzione troviamo i perni moncone, le corone, gli intarsi e le faccette dentali.
</li>
<li>
<b>protesi fissa di fissazione:</b> la protesi dentale fissa di fissazione consente di arrestare e distribuire in modo corretto le forze masticatorie. Esempio di protesi fisse di fissazione sono le ferule di fissazione, adoperate per correggere i “denti vacillanti” a seguito di parodontopatie. <br/>
<b> suddetti elementi sono denominati</b> corone se poggiati su denti adiacenti preventivamente limati per l’occasione, ponti se il fine ultimo è quello di ripristinare il sorriso attraverso l’applicazione dei denti mancanti e protesi su impianti se applicati su impianti immessi nell’osso.<br/>
<b>Intarsi</b> (particolari forme di ricostruzioni dentali) e faccette (gusci in ceramica applicabili su denti anteriori e posteriori per finalità estetiche) sono parte integranti della protesi dentale fissa, al cui successo negli ultimi anni hanno contribuito l’utilizzo delle moderne tecnologie di realizzazione computer assistita come la CAD / CAM.
</li>
</ul>
<br/>
<b>Come già abbiamo avuto modo di vedere</b>, senza l’utilizzo della tecnologia CAD/CAM, la produzione della protesi dentale fissa non può che avvenire manualmente con la presa delle impronte dentarie nella bocca del paziente attraverso l’inserimento di una pasta ad hoc su un supporto di metallo dedicato, impronte successivamente inviate al laboratorio odontotecnico per la realizzazione del calco in gesso.<br/>
<b>Grazie alla tecnologia computerizzata CAD/CAM</b>, oggi è possibile progettare protesi fisse al computer: l’odontotecnico ha la possibilità di riprodurre in tempi rapidi il modello in gesso ricavato dalle impronte scannerizzandolo con uno scanner tridimensionale in grado di riprodurre il manufatto in digitale. Una volta riprodotto, eventuali modifiche di forma, dimensioni e spessore della protesi fissa possono essere realizzate tramite il computer.<br/>
Conclusa la fase di progettazione CAD, ha inizio la fase CAM: il software realizza un file digitale per poi inviarlo ad una fresatrice computer assistita dalla tecnologia avanzata in grado di elaborarlo e intagliare e fresare il blocco di materiale scelto per la protesi fissa attenendosi alle informazioni fornite.ali <br/>
<b>L’utilizzo dell’avanzata tecnologia computerizzata CAD/CAM</b> ha permesso agli specialisti nel campo odontotecnico e ai pazienti di beneficiare di una lunga serie di vantaggi nella produzione di protesi fisse:<br></br>
1.	Maggiore resistenza rispetto al passato dovuta alla mancanza di bolle di fusione;<br></br>
2.	Maggiore precisione grazie all’utilizzo del computer;<br></br>
3.	Realizzazione di manufatti molto sottili (aspetto fondamentale nella produzione di faccette);<br></br>
4.	Possibilità di visualizzare un’anteprima del risultato;<br></br>
5.	Ridurre considerevolmente i tempi di produzione delle stesse eliminando una lunga serie di passaggi.<br></br>
    <br></br>
    <b>È consigliabile ricorrere a una protesi fissa</b> se si dispone di denti sani: difatti per la realizzazione di corone e ponti fissi è fondamentale appoggiarsi ad un numero consistente di pilastri residui, non eccessivamente distanti uno dall’altro ed in grado di garantire una stabilità adeguata.<br></br>
    <b>In questo caso, una protesi dentale fissa</b> è a tutt’oggi la soluzione migliore per sostituire i denti mancati e/o gravemente danneggiati, in quanto gli impianti sono in grado di integrarsi alla perfezione con i denti naturali sia dal punto di vista estetico che biocompatibile, riproducendo in toto la loro funzionalità (a comprova di ciò il fatto che la pulizia viene eseguita normalmente con lo spazzolino) garantendo un’invidiabile stabilità, un sorriso impeccabile e una masticazione agevole.<br></br>
<br></br> <br></br>



               
               </div>
            
              <div className='single-img max-height500'>
                <img
                  src="./img/news/allon6/allon63.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>

              <div className='content'>
              <br/> <h4>	Vantaggi della Protesi Dentale Fissa  </h4><br/>
                <ul>
                  <li>
                  Denti perfetti;
                  </li>
                  <li>
                  	Interventi rapidi (24-48 ore);
                  </li>
                  <li>
                  	Durata;
                  </li>
                  <li>
                  Funzionalità;
                  </li>
                  <li>
                  	Risparmio;
                  </li>
                  <li>
                  	Utilizzabile anche in caso di edentulia (mancanza totale di denti);
                  </li>
                  <li>
                  	Tecnica non invasiva.
                  </li>


                </ul>
<br></br>
Generalmente una protesi totale fissa  ha un prezzo che oscilla, come è facilmente intuibile, dal tipo di protesi dentale fissa utilizzata (All on four, All on six) e dalla qualità e dall’affidabilità dei materiali utilizzati.
          <br></br><br></br>
         <b> Sicuramente, la soluzione ideale in caso di mancanza totale o parziale di denti</b>, in grado di garantire una masticazione perfetta evitando così fastidiosi problemi digestivi e una fonazione perfetta (l’edentulismo può pregiudicare il corretto posizionamento della lingua, provocando, di conseguenza una fonazione non corretta).
Un intervento poco invasivo e pertanto indolore: la procedura di inserimento prevede il posizionamento di 8 impianti in Titanio, su cui successivamente verrà fissata una protesi dentale fissa in ceramica per la sostituzione dell’intera arcata dentale.
    
 
  
              </div>

              
        </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/allon6/allon61.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/allon6/allon64.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img pimg-5'>
                <img
                  src="./img/news/allon4/allon44.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            </div>

            
           
          </div>
        </section>         
        </CaseWrapper>

      </ScrollTop>

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Protesifissi);
