import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Reveal from 'react-reveal/Reveal';
import {Helmet} from "react-helmet";
import Slider from '../components/Sliderhome';
import Featurehome from '../components/Featurehome';
import Abouthome from '../components/Abouthome';
import Carouselfeature from '../components/Carouselfeature';
import Carouselclient from '../components/Carouselclient';
import Carouselclientb from '../components/Carouselclientb';
import Carouselprojects from '../components/Carouselprojects';
import Carouselteam from '../components/Carouselteam';
import ContactForm from '../components/ContactForm/ContactForm';
import Accomplish from '../components/Accomplish';
import Carouselnews from '../components/Carouselnews';
import Footer from '../components/Footer';
import Testimonial from '../components/Testimonial/Testimonial';
import Popup from '../components/Popup/Popup';
import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
import { Link } from 'react-router-dom';
import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const bstyle ={
  background: '#5aa6a6',
  fontweight: '600',
  border:'none',
    fontsize: '11pt',
    letterspacing: '1px',
    borderRadius: '5px',
    padding: '0 44px',
    height: '45px',
    marginTop: '25px',
    marginLeft: 'auto',
    marginRight: '13px',
    color: '#fff'
}

const Home = ({ history }) => {


  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setshowModal(true)
  //   }, 10000)
  // }, [])

  // const [showModal, setshowModal] = useState(false);

  // const closeModal = () =>{
  //   setshowModal(false);
  // }

  const config = {
    api: `nnn`,
    title: 'Contattaci',
    description: '',
    successMessage: 'Grazie per contattare Sorridial.',
    errorMessage: 'Compila il modulo completo',
    fields:{
      firstName: '',
      lastName: '',
      email: '',
      msg: ''
    },
    fieldsConfig:  [
     { id: 1, label: 'Nome', fieldName: 'firstName', name:'firstName', type: 'text',placeholder:'Your First Name', isRequired: true , klassName:'first-name-field'},
     { id: 2, label: 'Cognome', fieldName: 'lastName', name:'lastName', type: 'text', placeholder: 'Your Last Name', isRequired: true , klassName:'last-name-field'},
     { id: 3, label: 'E-mail', fieldName: 'E-mail', name:'email', type: 'email', placeholder: ' Your Email', isRequired: true , klassName:'email-field'},
    { id: 4, label: 'Messaggio', fieldName: 'Messaggio', name:'Messaggio', type: 'textarea',placeholder:'Write something.....', isRequired: true , klassName:'message-field'},
      { id: 5, label: 'Carica la panoramica dentale qui', fieldName: 'file', type: 'file',placeholder:'Write something.....', isRequired: true , klassName:'upload-field'}
    ]
  }

  return (
    <div>


      <Efect/>
      <Efect1/>
      <Efect2/>

      <Reveal effect="fadeIn">
        <section className="jumbotron jumbomain">
            <Slider />
        </section>
      </Reveal>
      
      <Featurehome/>
      
      <Reveal effect="fadeInUp">
        <Abouthome/>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className='container-fluid'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'> VANTAGGI DEL TURISMO DENTALE </div>
            </div>
            <div className='col-12'>
              <Carouselfeature/>
            </div>
            <button className="scopributton" style={bstyle}>
                    
                    <Link to={'/about'}>
                    Scopri di Piu
                    </Link>
                    
            </button>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className='container-fluid py-0'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'> STUDI DENTALI: </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 p-0'>
              <Carouselprojects/>
            </div>
          </div>
        </section>
      </Reveal>

    {/* <Popup showModal={showModal}  closeModal={closeModal}/> */}

      <Reveal effect="fadeInUp">
        <section className='container-fluid'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'>SPECIALIZZATO IN: </div>
            </div>
            <div className='col-12'>
              <Carouselteam/>
            </div>
      
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <Accomplish/>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className='container-fluid'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'>CONTATTACI: </div>
            </div>
            <div className='col-12'>
            <ContactForm config={config} />
            </div>
      
          </div>
        </section>
      </Reveal>

     

      <Reveal effect="fadeInUp">
        <section className='container-fluid'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'> DICONO DI NOI: LA ESPERIENZE DEI NOSTRI CLIENTI </div>
            </div>




            {/* <Helmet>
              <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>

             
            </Helmet>
            <div class="trustpilot-widget" data-locale="it-IT" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="60feb4a742a0450001366ad9" data-style-height="52px" data-style-width="100%">
                <a href="https://it.trustpilot.com/review/sorridial.com" target="_blank" rel="noopener">Trustpilot</a>
              </div> */}
            <div className='col-12'>
            <Testimonial/>
            </div>
          </div>
        </section>
      </Reveal>

      <WhatsAppWidget companyName='Sorridial' phoneNumber='+355694048535'  sendButton='Contattaci' message='Ciao! Come possiamo aiutarla?' textReplyTime='' />

      {/* <Reveal effect="fadeInUp">
        <section className='container-fluid pt-0'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'> SEGUI IL NOSTRO BLOG </div>
            </div>
            <div className='col-12'>
              <Carouselnews/>
            </div>
          </div>
        </section>
      </Reveal> */}

  
      <Footer />

    </div>
  );
};

export default withRouter(Home);
