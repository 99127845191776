import React from "react";
import { withRouter } from "react-router-dom";
import Reveal from 'react-reveal/Reveal';
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";
import AboutAlbania from '../components/AboutAlbania';
import Carouselclient from '../components/Carouselclient';
import Carouselclientb from '../components/Carouselclientb';
import Carouselteam from '../components/Carouselteam';
import Accomplish from '../components/Accomplish';
import Carouselprojects from '../components/Carouselprojects';
import Footer from '../components/Footer';

import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
  

const About = ({ history }) => {

  return (
    <div>
      <Efect/>
      <Efect1/>
      <Efect2/>


      <Reveal effect="fadeIn">
      <section className='header__baner jumbotron imgtop'>
          <h3> COSA DOVRESTI SAPERE</h3>
        	<img src="./img/vlora.webp" className="img-fluid" alt="Albania" />
        </section>
       </Reveal>

      <Reveal effect="fadeIn">
        <section className='container-fluid pb-0'>
	        <div className='row m-2-hor'>
	        	<div className='col-md-4'>
	        		<h1>ALBANIA</h1>
	        	</div>
	        	<div className='col-md-8'>
	        		<div className='content'>
              Albania è  un paese molto amichevole che si trova in Europa, con una popolazione di appena 
              2,8 milioni. È anche una destinazione per i buongustai,
               con una cucina raffinata a prezzi accessibili e deliziosi piatti locali.
               L'Albania vede circa 4 milioni di visitatori entrare nei suoi confini ogni anno, 
               significativamente meno di altri paesi vicini come l'Italia (50,7 milioni) e 
               la Grecia (23,6 milioni). Tuttavia, con il suo clima mediterraneo, le spiagge dorate,
                i paesaggi montuosi, la storia interessante, i prezzi accessibili, la deliziosa cucina 
                locale e le persone amichevoli, il paese ha tutto ciò che un turista può desiderare da un'avventura europea.
	        		</div>
	        	</div>
	        </div>
	    </section>
	   </Reveal>

	  <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"È UN PAESE SICURO"} 
        dsc1={"L'Albania è uno dei luoghi meno pericolosi d'Europa. Secondo il Foreign Office britannico, la sicurezza pubblica è generalmente buona. Puoi viaggiare in Albania con i bambini, con una persona cara o da solo (donne incluse) senza preoccuparti della tua sicurezza come potresti fare in alcuni paesi. L'unico pericolo quando si visita l'Albania come turista è tornare a casa 2 kg più pesante di quando sei arrivato."} 
        title2={"L'ALBANIA E MOLTO CONVENIENTE"} 
        dsc2={"Rispetto alle nazioni vicine, il turismo in Albania è molto conveniente. Puoi trascorrere una settimana fenomenale viaggiando attraverso il paese senza spendere troppo. Quando si tratta di attività, i parchi sono gratuiti e, sebbene i musei richiedano i biglietti per entrare, le tariffe sono basse."} 
        imgsrc={"./img/safeAlbania.webp"}
        position={"left"} />
      </Reveal>


      <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.5) ) , url('./img/welcome1.webp')" }}>
              <div className='herocaption'>
               
                <ClientTag>Ospitalità</ClientTag>
                <Title>Gli albanesi sono amichevoli</Title>
                <TechniqueTitle>
                Una delle cose migliori di un viaggio in Albania è che ovunque tu vada, 
                dalla capitale alla riviera, dalle montagne ai centri storici, sarai accolto
                 dalla gente del posto. Quindi non esitare a chiedere indicazioni o altre 
                 informazioni: ci sarà sempre qualcuno che ti aiuterà a trovare quello che stai
                  cercando, sia esso un alloggio, un posto dove mangiare o consigli sui trasporti.
                  La maggior parte degli albanesi parlano l’inglese e l’italiano e il turismo si sta 
                sviluppando all'interno del paese ed è accolto come una parte fondamentale dell'economia del paese.
                </TechniqueTitle>
              </div>
          </Hero>
        </Reveal>

      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"IL CIBO ALBANESE E DELIZIOSO"}
        dsc1={"Porta con te un paio di pantaloni elastici, perché la cucina albanese è fantastica. L'Albania e' la miglior rappresentante della cucina mediterranea. È anche un esempio di dieta mediterranea basata sull'importanza dell'olio d'oliva, della frutta, della verdura e del pesce. I piatti sono molto gustosi e convenienti. Con cibi caratterizzati dalle zone rurali, dalle regioni montuose e dalla costa, ci sono così tanti sapori eccitanti da provare in Albania. I piatti migliori sono gustosi e convenienti. Assicurati di provare il piu piatti possibile."}
        imgsrc={"./img/food.webp"}
        position={"left"}       
        />
      </Reveal>

      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"L'ALBANIA E TOLLERANTE VERSO MOLTE RELIGIONI"}
        dsc1={"Gli albanesi sono sia cristiani che musulmani, cattolici, ortodossi e bektashi. Per secoli i territori popolati da albanesi sono stati notevoli per una tolleranza interreligiosa unica. La religione non è mai stata in passato e non è ora fonte di conflitto nella società. Nella storia dei religiosi albanesi, le comunità si sono sempre susseguite con una pacifica cooperazione. Questa tolleranza deriva dalla consapevolezza degli albanesi di appartenere alla stessa nazione che ha una forte identità etnica indipendentemente da fattori esterni che influenzano la determinazione degli albanesi di altre religioni."}
        imgsrc={"./img/religion.webp"}       
        position={"right"} 
        />
      </Reveal>

      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"L'ALBANIA HA BELLISSIME SPIAGGE "}
        dsc1={"Se stai già pianificando di visitare l'Albania come turista, probabilmente conosci già le bellissime spiagge che costeggiano la costa albanese.Se ti trovi nella capitale Tirana ma hai voglia di un giorno o due in spiaggia, dirigiti verso la vicina città costiera di Durazzo dove troverai la spiaggia di Durazzo, la spiaggia più grande e più visitata dell'Albania. Altrimenti, puoi dirigerti a sud verso la riviera albanese, dove le spiagge dorate incontrano le acque turchesi accanto ai villaggi costieri più tradizionali della riviera."}
        imgsrc={"./img/beaches.webp"}   
        position={"left"}
        />
      </Reveal>

      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"C'È MOLTO DI PIÙ DELLE SPIAGGE"}
        dsc1={"Sebbene la riviera albanese e le sue splendide spiagge siano ottime ragioni per visitarla, il paese ha molto altro da offrire. Le montagne, comprese le Alpi albanesi e le catene montuose dello Sharr e del Pindo, corrono lungo il paese. I siti archeologici includono tumuli preistorici, nonché antichi templi e forti greci e romani e stabilimenti termali medievali. Il paese è anche ricco di laghi e fiumi, foreste e antichi borghi che ti toglieranno il fiato. Le vacanze albanesi dovrebbero sempre essere organizzate con un sacco di tempo a disposizione per esplorare le numerose meraviglie naturali e storiche del paese."}
        imgsrc={"./img/culture.webp"}       
        position={"right"} 
        />
      </Reveal>


      
      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"PREZZI CONVENIENTI SULLE CURE DENTALI"}
        dsc1={"Generalmente le cure dentali in Albania hanno mediamente un costo che si muove sui 60 – 70 % in meno dei prezzi in Italia."}
        imgsrc={"./img/prices.webp"}
        position={"left"}       
        />
      </Reveal>

      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"TEMPI RAPIDI DEGLI INTERVENTI ODONTOIATRICI"}
        dsc1={"In merito ai tempi di ricezione del servizio, siamo orgogliosi per il fatto che offriamo un servizio molto veloce e senza burocrazie, senza lunghi tempi di attesa. Noi le assicuriamo il minor tempo possibile del servizio che vi offriamo."}
        imgsrc={"./img/time.webp"}       
        position={"right"} 
        />
      </Reveal>

      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"ASSISTENZA CONTINUA"}
        dsc1={"Le offriamo la possibilita’ di avere un asistenza per eccellenza, 24 / 7. Sara assistito prima dell’intervento, durante la sua permanenza, e anche dopo, al suo ritorno in Italia, ricevendo cosi’ risposta a tutte le sue domande."}
        imgsrc={"./img/assistant.webp"}   
        position={"left"}
        />
      </Reveal>


      
      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"VACANZA GRATUITA"}
        dsc1={"Scegliendo i nostri servizi per le sue cure dentali, lei non ha solo la possibilita di avere una soluzione per la sua salute orale, bensi’ ha la possibilita’ di fare una vacanza o minivacanza in Albania. Durante la sua permanenza, puo’ sfruttare il suo tempo libero nell’ambito turistico, il tutto organizzato in base agli orari suoi disponibili."}
        imgsrc={"./img/vacations.webp"}
        position={"right"}       
        />
      </Reveal>

      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"NESSUNA BARRIERA LINGUISTICA"}
        dsc1={"Ti preoccupa il fatto della comunicazione in lingua straniera? non ci sono problemi. Il nostro team e le cliniche con le quali collaboriamo hanno un ottimo italiano, e non solo, bensi’ anche inglese, greco eccetera. L’Albania e’ un paese in cui l ‘ 80% parla l’Italiano quasi livello madrelingua."}
        imgsrc={"./img/multilingual.webp"}       
        position={"left"} 
        />
      </Reveal>

      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"VICINANZA GEOGRAFICA"}
        dsc1={"I nostri paesi sono geograficamente vicini, il che’ fa si’ che la distanza non sia un problema, dato che distiamo di solo 1h e 20 min in aereo."}
        imgsrc={"./img/italbania.webp"}   
        position={"right"}
        />
      </Reveal>

      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"PROCEDURE SEMPLICI"}
        dsc1={"La burocrazia dele carte e delle procedure  e’ sempre stata un perdi tempo. Noi abbiamo deciso di seguire dei passi molto facili. Lei ci manda la sua panoramica, ed entro 24-48 ore noi provvediamo a portarle il suo preventivo, dove le viene spiegato in dettagli sia il servizio dentale a lei necessario, che l’amontare da pagare. Se il preventivo e’ di suo gradimento, lo firma e ce lo invia via mail oppure Whatsapp."}
        imgsrc={"./img/planning.webp"}       
        position={"left"} 
        />
      </Reveal>

         
      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"MATERIALI E STRUMENTI DI ELEVATA QUALITA’ E CERTFICATI"}
        dsc1={"I materiali usati dai nostri operatori dentistici, dalle nostre cliniche, come porcellana, metal porcellana, zirconio, eccetera, sono tutti in pieno rispetto delle normative vigenti europee, e per lo piu sono tutti certificati. Ove previsto il materale usato va presentato dietro certificati verificato per la sua provenienza e qualita’."}
        imgsrc={"./img/technology.webp"}
        position={"right"}       
        />
      </Reveal>

      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"SOGGIORNO GRATUITO NELLE MIGLIORI STRUTTURE ALBERGHIERE"}
        dsc1={"Offriamo un servizio impeccabile, sistemandola nelle migliore strutture alberghiere della citta’. Avra’ a che fare con delle strutture molto accoglienti, e con un servizio che la lascera’ molto soddisfatto. Allo stesso tempo, le mettiamo a disposizione il servizio guida turistico per dentro citta, citta vicine o anche lontane, in base alla sua disponibilita’ del tempo libero ed alla sua esigenza."}
        imgsrc={"./img/hotel.webp"}       
        position={"left"} 
        />
      </Reveal>

      <Reveal effect="fadeInUp">
        <AboutAlbania 
        title1={"CLIMA MEDITERRANEO"}
        dsc1={"Trattandosi di un clima mediterraneo, molto mite, sara’ garantito della sua soddisfazione durante il corso di tutto l’anno per poter visitarci."}
        imgsrc={"./img/sunny.webp"}   
        position={"right"}
        />
      </Reveal>

      <Footer />

    </div>
    
  );
};

export default withRouter(About);
