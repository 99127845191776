import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Implanti extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/news");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.3)),url('./img/news/allon4/allon49.webp')" }}>
              <div className='herocaption'>
               
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 pl-md-0'>
                      <ClientTag className='date'></ClientTag>
                      <Title>Definizione</Title>
                      <TechniqueTitle>
                      Le protesi dentali se mobili possono spesso comportare una serie di disagi per il paziente, come l’insufficiente stabilità dei denti all’interno della bocca o il fastidio di dover rimuovere sempre la protesi per poter effettuare l’igiene orale. Può inoltre subentrare una problematica di natura estetico -  psicologica, specie nei pazienti più giovani costretti a indossare le “dentiere”.

                      </TechniqueTitle>
                    </div>
                  </div>
                </div>
              </div>
          </Hero>
          </Reveal>

        <section className='container' id='detailproject'>
          <div className='row'>
            <div className='col-md-12'>
              <p className='content'>
                
              <h3 className='my-5'>Da cosa è costituito un impianto dentale All-on-4 ?
              </h3>
                
                <p>L’implantologia All on four è una tecnica odontoiatrica moderna che consente di riabilitare intere arcate dentali danneggiate e/o molto compromesse, o addirittura prive di denti. 
La tecnica all on four caratterizza una procedura che consiste nel posizionare con un solo intervento 4 impianti in particolari posizioni strategiche. Da qui la sigla All-on-4: tutto-su-4. 
 </p>
                
                


                 <h4 className='my-5'> Perche All-on-4?
                </h4> 


 <h6><b> Differenza dall'implantologia tradizionale: </b></h6>
<br></br>
A differenza della implantologia tradizionale, il metodo all on four permette al paziente il recupero del sorriso e della masticazione in breve tempo. Le protesi fisse vengono infatti avvitate sugli impianti.  
<br></br> <br></br>

Scegliere l’implantologia con all on four dà la possibilità a chi ha subito in passato la perdita dei denti, di riavere una dentatura completa e funzionale, riprendere le proprie abitudini alimentari e nello stesso tempo di avere di nuovo un bel sorriso.  Gli impianti dentali all on four forniscono al paziente una dentatura che sembra naturale perfettamente abbinata al palato e sono in genere ben tollerati anche dai pazienti più anziani. 
<br></br><br></br>
<h6><b>L’implantologia All on four è generalmente indicata per:</b></h6>
<br></br>
<ul>
<li>	pazienti con almeno un’arcata, superiore o inferiore, priva di denti</li>
<li>	pazienti affetti da una grave forma di patologia parodontale che necessitano di estrazioni e riabilitazione totale protesica </li>
<li>	pazienti con una scarsa ossatura dentale che non è sufficiente per realizzare protesi su impianti con le tecniche tradizionali</li>
</ul>

               
               </p>
              <p className='content'>
              La tecnica All on four prevede l’inserimento di quattro impianti, dai quali ne deriva appunto il nome, che vengono installati lungo il perimetro della gengiva, in punti strategici della bocca. Al di sopra di questi viene applicata una protesi fissa, che riprende fedelmente le misure e le dimensioni dell’arcata dentale originaria. 
<br/><br/>
Con la tecnica all on four è possibile ridurre i tempi e i costi dell’intervento, che sono appena superiori a quelli di una dentiera.
<br/><br/>
Prima di procedere all’intervento con il metodo All on four, viene eseguita un’accurata pianificazione del trattamento da parte degli specialisti. 
Viene in seguito effettuato un approfondito esame radiografico per la valutazione della quantità e della qualità dell’osso e si acquisiscono le impronte delle arcate dentarie.
Viene eseguito anche un esame ai denti che, con un software particolare, consente una visione tridimensionale della bocca del paziente. Attraverso questa indagine, è possibile osservare con precisione lo scheletro delle mandibole e dei mascellari in forma reale e simulare l’impianto, scegliendone in anticipo dimensione, lunghezza e inclinazione. 
  
              </p>
              <div className='single-img max-height500'>
                <img src="./img/news/allon4/allon48.webp"
                  className="img-fluid"
                />
              </div>

              <p className='content'>
              <br/> <h5>Come si svolge l’intervento    </h5><br/>
              L’intervento si svolge di norma con una semplice anestesia locale. Il paziente viene sottoposto a terapia antalgica che consente di non gonfiarsi e di essere tenuto sotto controllo in tutto il percorso di cura. L’assistenza è continua fino a quando il paziente non recupera il proprio sorriso e la sua bocca non viene completamente riabilitata. 
<br/><br/>
Gli impianti dentali all on four sono perni realizzati in titanio (materiale biocompatibile che non provoca reazioni da corpo estraneo) che vengono inseriti nell’osso. 
L’impianto endosseo diventa così parte dell’organismo, riabilitando l’arcata superiore compromessa. 
 
  
              </p>
            
              <h3 className='my-5'>I vantaggi dell'All-on-four</h3>
      
          <p className='content'>
                 <br></br>
              <ul>
                <li>
                In passato per riabilitare un’arcata completa era necessario inserire 6 o più impianti, innesti d’osso e potevano passare anche 18 mesi prima di riavere una dentatura fissa. Questo significava che un paziente poteva essere spesso senza denti, doveva portare una protesi mobile per un anno o più. 
                </li> <br></br>
                <li>Con la tecnica All-on-4, dopo una seduta si valuta, si studia, e si lavora il caso .
Con l’implantologia All on four è possibile avere una dentatura completa in breve tempi, con denti fissi. 
Il metodo All on four è in grado di dare stabilità anche con un volume osseo minimo.
 </li>
                <br></br>
                <li>E’ la soluzione ideale per arcate edentule o comunque con pochissimi denti e può sostituire la dentiera mobile. Essendo saldamente ancorata agli impianti osteointegrati, il paziente non avrà nessun problema di dislocazione della protesi durante la masticazione.
E’una soluzione più economica rispetto ad altre perché permette di inserire il numero massimo di denti con il numero minimo di impianti.
</li>
                <br></br>
                
          
              </ul>
              
             

          </p>
        </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/allon4/allon42.webp"
                  className="img-fluid"
                />
              </div>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/allon4/allon44.webp"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img pimg-5'>
                <img
                  src="./img/news/allon4/all1.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            </div>
            <div className='col-md-12'>
              <h3 className='my-5'>All on four arcata superiore</h3>
              <p className='content'>
              E’ possibile applicare la tecnica all on four anche solo alla arcata superiore, laddove questa si presenti compromessa o danneggiata.
Il concetto di carico immediato significa che vengono estratti i denti poi installati gli impianti e dopo le corone provvisorie, tutto in una sola seduta. Con carico immediato si intende un ponte fisso in solo 12 ore dall’inizio del trattamento. Dopo 6 mesi viene inserito il ponte fisso definitivo. Il lavoro a carico immediato può esistere anche nel caso in cui uno o più denti siano già stati rimossi.
</p>
            </div>

            <div className='col-md-12'>
              <h3 className='my-5'>All on four arcata inferiore</h3>
              <p className='content'>
              Esiste naturalmente anche la tecnica all on four dell’arcata inferiore, in cui si procede alla rimozione dei denti danneggiati o all’innesto dei nuovi mediante protesi che consentono una riabilitazione istantanea delle strutture dentali. 
              </p>
              <p className='content'>
              L’all on 4 dell’arcata inferiore prevede che i 4 impianti vengano appositamente posizionati. La minor invasività e il minor utilizzo di costosi componenti, ha fatto sì che molti pazienti oggi richiedano questa soluzione per i denti. 
              </p>
              
            </div>


            
           
          </div>
        </section>         
        </CaseWrapper>

      </ScrollTop>

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Implanti);
