import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Implanti extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/news");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.3)),url('./img/news/veneers/veneers9.webp')" }}>
              <div className='herocaption'>
               
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 pl-md-0'>
                      <ClientTag className='date'></ClientTag>
                      <Title>Definizione</Title>
                      <TechniqueTitle>
                      
                      Sognate un bel sorriso bianco? Desiderate che sia splendente come il sorriso delle celebrità, degli attori famosi e dei personaggi TV, o semplicemente non vi piace la forma, il colore o la posizione dei propri denti?

Lo sapevate che l’80 percento delle celebrità ha subito interventi di chirurgia estetica e quel bel sorriso lo devono alle faccette in ceramica – Veneers? 

                      </TechniqueTitle>
                    </div>
                  </div>
                </div>
              </div>
          </Hero>
          </Reveal>

        <section className='container' id='detailproject'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='content'>
                
              <h3 className='my-5'>COSA SONO LE FACCETTE E-MAX

              </h3>
                
                <p>Le faccette di ceramica – Veneers, fatte in Emax ceramica sono di spessore da 0.3 mm a 0.5 mm e vengono incollate alla parte anteriore dei denti per migliorare l’aspetto estetico, correggere erosioni, danni, colore o la posizione del dente. </p>
                
                <p><b>Sono sottili, bianche e traslucide, in questo modo mimano perfettamente i denti naturali. Le faccette si applicano al dente con la tecnica adesiva la quale gli da una lunga durata ed eccezionale resistenza. Possono essere utilizzate sui denti frontali e posteriori.</b></p>

              

                <h4 className='my-5'> QUANDO APPLICARE LE FACETTE

                </h4>


 

Lo sapevate che le faccette sono state utilizzate inizialmente a Hollywood per migliorare il sorriso dei attori? Ora anche in Albania potrete avere il sorriso Hollywoodiano!
Le faccette si utilizzano nei casi seguenti:
danni o scheggiature dei denti – grandi o piccoli difetti di natura estetica o funzionale
la pigmentazione o lo scolorimento dei denti – denti resistenti alle tecniche di sbiancamento
denti diastemi o storti – se il paziente non vuole sottoporsi a terapie ortodontiche, è possibile correggere anomalie minori 
<br></br> <br></br>



               
               </div>
              
              <div className='single-img max-height500'>
                <img
                  src="./img/news/veneers/veneers5.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            
              <h3 className='my-5'>COME SI APPLICANO LE FACCETTE?
</h3>
      
          <div className='content'>
          La procedura di applicazione delle faccette inizia con un’impronta dentale e la creazione dell’impronta “wax-up” per vedere il futuro aspetto dei denti ed evitare l’eccesiva rimozione dello smalto dentale.
Poi si elimina uno strato di smalto, da 0.3 mm a 0.5 mm e si prende nuovamente l’impronta che sarà portata nel laboratorio odontotecnico dove le faccette verranno create. Le faccette vanno testate per assicurarsi che aderiscano bene e che la forma ottenuta sia quella desiderata. <br></br><br></br>
             
              
La procedura di applicazione delle faccette inizia con un’impronta dentale e la creazione dell’impronta “wax-up” per vedere il futuro aspetto dei denti ed evitare l’eccesiva rimozione dello smalto dentale.
Poi si elimina uno strato di smalto, da 0.3 mm a 0.5 mm e si prende nuovamente l’impronta che sarà portata nel laboratorio odontotecnico dove le faccette verranno create. Le faccette vanno testate per assicurarsi che aderiscano bene e che la forma ottenuta sia quella desiderata. <br></br>
Sono importanti i controlli regolari per togliere i depositi di tartaro e placca e così prolungare la longevità delle faccette.

          </div>
        </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/veneers/veneers6.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/veneers/veneers7.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img pimg-5'>
                <img
                  src="./img/news/veneers/veneers8.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            </div>
            
           
          </div>
        </section>         
        </CaseWrapper>

      </ScrollTop>

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Implanti);
