import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/vertical.css';
import { Link } from 'react-router-dom';

const content = [
  {
    title: "Turismo Dentale Albania",
    description:
      "Combina il tuo trattamento dentale con una splendida vacanza.",
    button: "Scopri di più",
    link: "contact",
    image: "./img/bg-1.jpg",
    gradient: "linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.6))"
  },
  {
    title: "Sentiti Bene Con Il Tuo Sorriso e Il Tuo Dentista",
    description:
      "Nella nostra pratica, vieni sempre al primo posto.",
    button: "Scopri di più",
    link: "/contact",
    image: "./img/bg-2.jpg",
    gradient: "linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.5))"
  },
  {
    title: "Cure Odontoiatriche Esperte Con Le Ultime Tecnologie",
    description:
      "Fai un investimento facile e conveniente per la tua salute.",
    button: "Scopri di più",
    link: "/contact",
    image: "./img/bg-3.jpg",
    gradient: "linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.4))"
  }
];

const pStyle = {
  fontSize: '18px',
  color:'white',
  display:'block',
  marginBottom:'18px'
};

const bstyle ={
  border: '1px solid #fbf8f2',
  background: '#dddddd82'
}

export default () => (
  <Slider className="slider-wrapper" autoplay={6000}>
            {content.map((item, index) => (
              <div
                key={index}
                className="slider-content"
                style={{ background: `${item.gradient} , url('${item.image}') no-repeat center center` }}
              >
                <div className="inner">
                  <h1>{item.title}</h1>
                  <span style={pStyle}>{item.description}</span>
                  <Link to={item.link}>
                  <button className='sliderbutton' style={bstyle} to={item.link}>
                    <span className="shine"></span>
                    
                      {item.button}
                   
                  </button>
                  </Link>
                </div>
              </div>
            ))}
        </Slider>
);
