import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Implanti extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/news");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.3)),url('./img/news/implants/dental-implant.webp')" }}>
              <div className='herocaption'>
               
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 pl-md-0'>
                      <ClientTag className='date'></ClientTag>
                      <Title>Definizione</Title>
                      <TechniqueTitle>
                      
Gli impianti dentali sono dispositivi medici di tipo chirurgico che vengono inseriti all’interno delle ossa mascellari in seguito alla perdita di uno o più denti o alla loro agenesia (assenza del dente permanente) per sostituirli e restituire al paziente la funzione masticatoria ed estetica. Essi, in altri termini, sono un sostituto artificiale della radice del dente e richiedono un vero e proprio intervento chirurgico.
Prima di procedere con l’impianto, il paziente, se necessario, deve essere sottoposto a terapia parodontale, intendendo per essa una modalità terapeutica finalizzata a migliorare un’eventuale patologia infiammatoria del tessuto gengivale profondo (parodontite). Ricordiamo, infine, che se si desidera un impianto dentale, è necessario rivolgersi ad uno specialista e informarsi su tutto. 

                      </TechniqueTitle>
                    </div>
                  </div>
                </div>
              </div>
          </Hero>
          </Reveal>

        <section className='container' id='detailproject'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='content'>
                
              <h3 className='my-5'>Da cosa è costituito un impianto dentale?
              </h3>
                
                <p>Un impianto dentale è costituito da una radice artificiale in titanio che, mediante un processo definito osteointegrazione, permette la sostituzione di uno o più denti persi. </p>
                
                <p><b>Le parti che costituiscono gli impianti dentali sono tre:</b></p>

                <ul style={{listStyleType:"circle"}}>
                <li><b> la vite endossea </b> ( vite in titanio ) che viene inserita all’interno dell’osso e che svolge la stessa funzione delle radici naturali del dente: garantisce forza alla masticazione e stabilità del dente</li>
                <br></br>
                <li><b>l’abutment  </b> ( moncone ) ovvero l’elemento di raccordo tra la vite endossea e la protesi dentaria</li>
                <br></br>
                <li><b>la protesi dentaria   </b> ( moncone ) ovvero l’elemento di raccordo tra la vite endossea e la protesi dentaria</li>
                </ul>


                <h4 className='my-5'> Tipi di implantologia:
                </h4>


 <h6><b> Implantologia a carico differito ( a due tempi ) </b></h6>
<br></br>
L’impianto dentale a carico differito prevede un intervento di implantologia in cui viene inserito nell’osso l’impianto e su di esso viene applicata una vite di guarigione che va a chiudere, sigillare la parte cava dell’impianto. Tale tipo di impianto dentale viene definito a carico differito in quanto la protesi verrà installata solo dopo che sia trascorso un periodo di tempo ( tra 3 a 6 mesi) che consenta all’impianto di osteointegrarsi perfettamente all’osso mandibolare o mascellare. Trascorso tale periodo la protesi dentaria andrà a sostituire la vite di guarigione.
Implantologia a carico immediato
L’implantologia a carico immediato invece prevede l’installazione degli impianti dentali con una protesi provvisoria contemporaneamente all’estrazione del singolo dente o più denti. Viene definita implantologia a carico immediato in quanto la protesi provvisoria viene inserita entro 36-48 ore dal momento in cui viene inserito l’impianto dentale. 
<br></br> <br></br>

Grazie all’implantologia a carico immediato invece sarà possibile avere denti apparentemente naturali subito dopo l’estrazione o all’evento che ha causato la perdita di denti. Trascorsi 3- 4 mesi, ovvero il tempo necessario affinchè i tessuti possano guarire, la protesi provvisoria dell’impianto a carico immediato verrà sostituita da quella definitiva. 
<br></br><br></br>
<h6><b>Requisiti per l’implanto a carico immediato:</b></h6>
<br></br>
<ul>
<li>	Non devi soffrire di bruxismo.</li>
<li>	Devi presentare un buon supporto parodontale.</li>
<li>	L’osso mandibolare e l’osso mascellare deve essere sufficiente.</li>
</ul>

               
               </div>
              <p className='content'>
              Tuttavia, per poter utilizzare la tecnica del carico immediato, l’impianto deve avere un’ottima tenuta sull’osso e solo lo specialista, durante l’intervento di inserimento, sarà in grado di decidere per il carico immediato oppure per il carico differito.
              Come accennato, l’implantologia a carico immediato è utilizzabile sia in caso di impianti singoli o di numero ridotto, sia in caso di sostituzione di intere arcate dentarie. Il grande vantaggio consiste nella immediata riabilitazione e nella notevole riduzione dei disagi fisici e psicologici legati alle tecniche implantologiche tradizionali, caratterizzate da tempi di esecuzione e guarigione più lunghi.  
              </p>
              <div className='single-img max-height500'>
                <img
                  src="./img/news/implants/dental-implants.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            
              <h3 className='my-5'>Vantaggi degli impianti:</h3>
      
          <div className='content'>
                L’inserimento dell’impianto dentale a carico immediato è un intervento abbastanza articolato che si divide nelle seguenti fasi: <br></br><br></br>
              <ul>
                <li>
                 <b>	SORRISO SANO E BELLO </b>, gli impianti dentali hanno un aspetto molto naturale e funzionano come i denti naturali. Il dente sostituito vi farà tornare il sorriso naturale e migliorera la sua autostima. 
                </li> <br></br>
                <li><b>	MIGLIORE FUNZIONE MASTICATORIA </b> , il dente sostituito  permette di godere normalmente il cibo. Questo aiuta anche ad avere una migliore funzione discursiva ed articolatoria. </li>
                <br></br>
                <li><b>MIGLIORE SALUTE DENTALE GENERALE </b> ,  l’impianto dentale assicura una posizione regolare degli altri denti all’interno della sequenza dentale, impedisce il loro spostamento e protegge l’osso dal riassorbimento.</li>
                <br></br>
                <li>	
                <b>SOLUZIONE A LUNGO TERMINE </b> , se curati adeguatamente con una buona igiene orale e visite regolari, gli impianti possono durare tutta la vita.
                </li>
                <br></br>
                <li>
                <b>ASSENZA DI DOLORE </b> Il processo dell’inserimento dell’impianto è praticamente indolore. 
                </li>
                <br></br>
                <li>
                <b>GARANZIA A VITA </b> Garanzia a vita sul lavoro svolto. 
                </li>
          
              </ul>
              
              Qualora si tratti dell’impianto a carico differito, dopo l’inserimento della vite, il lembo gengivale viene richiuso con i punti di sutura e si attenderanno le tempistiche medie di osteointegrazione a seconda dell’arcata mascellare trattata (mascellare superiore: 4/6 mesi di guarigione; mascellare inferiore: 2/3 mesi di guarigione).

          </div>
        </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/implants/img1.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/implants/img4.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img pimg-5'>
                <img
                  src="./img/news/implants/img3.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            </div>
            <div className='col-md-12'>
              <h3 className='my-5'>Impianti dentali senza osso</h3>
              <p className='content'>
                  In presenza di un deficit osseo sia sull’arcata dentale superiore sia sull’arcata dentale inferiore le soluzioni sono molteplici, ma sicuramente gli interventi più comuni sono il rialzo del seno mascellare, la rigenerazione ossea o l’innesto osseo.</p>
            </div>

            <div className='col-md-12'>
              <h3 className='my-5'>Rischi e controindicazioni</h3>
              <p className='content'>
              Molto raramente si potrebbe incorrere il rischio della mancata integrazione dell’impianto, cioe’ l’organismo non riesce a fondere l’impianto con l’osso, pero’ comunque senza ulteriori complicazioni o peggioramenti. 
              </p>
              <p className='content'>
              Sporadicamente potrebbe presentarsi il rischio di un infiammazione o infezione, cosa drasticamente ridotta dalle procedure di sterilita’ in cui si sottopongono gli strumenti e I materiali usati nel trattamento. 
              </p>
              <p className='content'>
              La lesione al nervo e’ un altro rischio che potrebbe presentarsi nel 0.03 % dei casi. In questi casi si potrebbero avvertire disturbi temporanei nella sensibilita’ della parte inferior del viso. Il rischio di complicazioni aumeta nei soggetti fumatori.
              </p>
            </div>


            <div className='col-md-12'>
              <h3 className='my-5'>Qual è la durata degli impianti dentali?</h3>
              <p className='content'>
              Nell’implantologia a carico immediato è particolarmente diffusa la tecnica “All on Four”. Questa tecnica consente, mediante l’inserimento di 4 impianti dentali, di applicare protesi per un’intera arcata per i pazienti soggetti ad edentulia. L’implantologia All on Four infatti consente di installare 10-12 protesi su 4 impianti che vengono posizionati in modo strategico nella nostra bocca andando così ad assicurare una perfetta distribuzione della forza masticatoria. L’implantologia All on Four può essere effettuata sia per casi di edentulia riguardanti l’arcata superiore sia quella inferiore. Tale tecnica presenta alcuni vantaggi specifici:
              </p>

              <li>consente di risparmiare denaro in quanto vengono installati un numero minore di impianti dentali</li>
              <br></br>
              <li>consente di risparmiare tempo in quanto è possibile installare su di un’unica struttura tutte le protesi in una sola volta</li>
              <br></br>
              <li>intervento meno invasivo in quanto minore è il numero di impianti dentali da installare.
                  L’implantologia All on Four consente dunque al paziente di recuperare il sorriso e la funzione masticatoria entro 24 ore dall’installazione degli impianti dentali.</li>
              <br></br>
               <p>Un’altra metodologia è chiamata All on Six e prevede, al contrario della tecnica All on Four, l’installazione di 6 impianti dentali su cui posizionare le protesi definitive. Rispetto alla metodologia All on Four è meno utilizzata in quanto richiede l’installazione di un maggior numero di impianti dentali, 6 invece di 4. Sarà comunque il dentista a decidere quale tipologia di impianto dentale installare in base alle esigenze e alle caratteristiche del paziente.</p>
            </div>
           
          </div>
        </section>         
        </CaseWrapper>

      </ScrollTop>

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Implanti);
