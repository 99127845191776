import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2 } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Implanti extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/news");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    return (
      <Fragment>
        <Efectr />
        <Efectr1 />
        <Efectr2 />
        <ScrollTop>
          <CaseWrapper>
            <Reveal effect="fadeIn">
              <Hero className='mainhero' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.3)),url('./img/news/corone/corone8.webp')" }}>
                <div className='herocaption'>

                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-12 pl-md-0'>
                        <ClientTag className='date'></ClientTag>
                        <Title>Definizione</Title>
                        <TechniqueTitle>


                        </TechniqueTitle>
                      </div>
                    </div>
                  </div>
                </div>
              </Hero>
            </Reveal>

            <section className='container' id='detailproject'>
              <div className='row'>
                <div className='col-md-12'>
                  <p className='content'>

                    <h3 className='my-5'>PERCHÉ LE CORONE IN ZIRCONIO CERAMICA SONO LA MIGLIORE SOLUZIONE PER LA SOSTITUZIONE DEI DENTI?
                    </h3>


                    <p>Dopo l’impianto, la soluzione più comunemente utilizzata per compensare i denti mancanti sono le corone e ponti in ceramica, le quali sono esteticamente e funzionalmente piu’ simili ai denti naturali. Fino a poco tempo fa, le corone in metallo ceramica erano la soluzione migliore, ma negli ultimi anni hanno dominato esteticamente superiori, cosiddette non metalliche corone in ceramica, specialmente le corone in zirconio ceramica.<br />
                      La ricerca del materiale adatto per realizzare le protesi dentali dura da secoli, partendo dall’osso e dal legno, per arrivare più recentemente a oro e porcellana. Da qualche tempo si sta imponendo sul mercato un materiale particolare: lo Zirconio. Propriamente detto Ossido di Zirconio, unisce la resistenza dei metalli, la biocompatibilità dell’oro e il bianco brillante della porcellana: per questi motivi sta diventando sempre più popolare nel campo delle protesi dentarie ed è utilizzato soprattutto per le corone.
                    </p>




                    <h4 className='my-5'> Realizzare una protesi in zirconio
                    </h4>

                    Generalmente le protesi in zirconio vengono forniti ai laboratori odontotecnici sotto forma di blocco cubico, naturalmente di colore bianco che viene sagomato da una fresa computerizzata, facendogli assumere la forma desiderata e uscendo dalla macchina pronto per essere cementato su un dente naturale oppure su un impianto. Sempre di più negli ultimi mesi, si stanno diffondendo le corone in zirconia stratificata realizzate modellando un mix di ceramica e zirconia con l’obiettivo di realizzare una protesi perfetta sia nell’aspetto estetico che funzionale.<br /><br />
                    Tra l’altro, grazie alla durezza dello Zirconio, le corone realizzate in questo materiale possono essere meno spesse di quelle in metallo-ceramica, favorendo una minore limatura del dente naturale. Tutto questo procedimento può essere realizzato in tempi molto più brevi rispetto alle protesi tradizionali (solitamente alcune settimane).
                    <br></br> <br></br>

                    <div className='single-img max-height500'>
                      <img
                        src="./img/news/corone/corone1.webp"
                        className="img-fluid"
                        alt="Imageteam"
                      />
                    </div>

                    <br></br>
                    <br></br>

                    <h4>  Qualità estetiche dello zirconio  </h4>
                    Questa innovativa tipologia di protesi dentale inoltre, permette di raggiungere risultati estetici di grandissimo valore. La traslucenza dello zirconio emerge ancor di più grazie ad attenti processi di limatura e levigazione. Essa permette di produrre dei manufatti esteticamente perfetti e del tutto conformi alla dentatura originale di ogni singolo paziente. Le protesi in zirconio, infatti, proprio per le caratteristiche estetiche, sono assolutamente preferibili a quelle tradizionali per la sostituzione di elementi dentali ad alto impatto estetico: incisivi e canini.

                    <br></br><br></br>
                    <h6><b>Riassumendo…</b></h6>
                    <br></br>
                    i principali vantaggi dello scegliere una corona di questo tipo sono:<br />
                    <ul>
                      <li>	<b>Estremamente resistente</b> (meno rotture) e richiede una ridotta preparazione del dente naturale rispetto ad altri materiali.</li>
                      <li>	<b>	Ridotti tempi di attesa.</b> </li>
                      <li>		<b>Eliminazione del pericolo di rigetto o allergie</b> causato dalla presenza di alcuni metalli nelle protesi tradizionali.</li>
                      <li>
                        Eliminazione della sensibilità caldo/freddo tipica delle corone in metallo-ceramica.
                      </li>
                      <li>
                        Maggiore <b>qualità estetica</b> grazie alle caratteristiche di traslucenza e <b>brillantezza</b>
                      </li>
                    </ul>


                  </p>




                </div>
                <div className='col-md-6 p-md-0'>
                  <div className='single-img max-height300 pimg-5'>
                    <img
                      src="./img/news/corone/corone2.webp"
                      className="img-fluid"
                      alt="Imageteam"
                    />
                  </div>
                  <div className='single-img max-height300 pimg-5'>
                    <img
                      src="./img/news/corone/corone3.webp"
                      className="img-fluid"
                      alt="Imageteam"
                    />
                  </div>
                </div>
                <div className='col-md-6 p-md-0'>
                  <div className='single-img pimg-5'>
                    <img
                      src="./img/news/corone/corone6.webp"
                      className="img-fluid"
                      alt="Imageteam"
                    />
                  </div>
                </div>



              </div>
            </section>
          </CaseWrapper>

        </ScrollTop>

        <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Implanti);
