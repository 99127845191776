import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Implanti extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/news");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.3)),url('./img/news/allon6/allon65.webp')" }}>
              <div className='herocaption'>
               
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 pl-md-0'>
                      <ClientTag className='date'></ClientTag>
                      <Title>Definizione</Title>
                      <TechniqueTitle>
                      Il metodo All on 6, come dice il suo stesso nome, comprende l’inserimento di 6 impianti sui quali viene poi fissata una protesi fissa senza palato in composito. È indicata per i pazienti che nella parte frontale non hanno volume osseo a sufficienza, hanno difetti ossei notevoli o hanno dei dislivelli ossei, per questo motivo la posizione degli impianti si sposta nella parte laterale dell’arcata. Il metodo All on 6, a differenza del metodo All on 4, può richiedere il rialzo di uno o ambedue i seni mascellari, o anche la ricostruzione ossea. Per il metodo All on 6 vengono utilizzati gli impianti con la garanzia a vita.

                      </TechniqueTitle>
                    </div>
                  </div>
                </div>
              </div>
          </Hero>
          </Reveal>

        <section className='container' id='detailproject'>
          <div className='row'>
            <div className='col-md-12'>
              <p className='content'>
                
              <h3 className='my-5'>Come si svolge l’ intervento
              </h3>
                
                <p>Con l’implantologia All on 6 è possibile riavere tutti i denti in molto breve tempo. La protesi definitiva verrà poi applicata dopo 3-6 mesi dall’intervento, per dare il tempo necessario alla guarigione dei tessuti e all’osteointegrazione degli impianti evitando così quei micromovimenti che possono disturbare il processo di osteointegrazione. 
 </p>
                
                <p><b>Di seguito gli step dell’All on 6:</b></p>

                <ul style={{listStyleType:"circle"}}>
                <li>	Estrazione dei denti ancora presenti ed eliminazione delle infezioni;</li>
                <br></br>
                <li>	Inserimento di 6 impianti nell’arcata;</li>
                <br></br>
                <li>	Applicazione della protesi provvisoria per proteggere i 6 impianti inseriti.</li> <br/>
                <li>	Applicazione della protesi fissa definitiva nei 3-6 mesi successivi dall’intervento.</li>
                </ul>


                 <h4 className='my-5'> Vantaggi dell’implantologia All on 6
                </h4> 


 
<br></br>
<ul>
<li>	Riduzione dei costi dovuta all’utilizzo di un numero ridotto di impianti per la ricostruzione di un’intera arcata;</li>
<li>		Riduzione di tempo dovuta alla possibilità di applicare tutte le protesi fisse su un’unica struttura in un’unica seduta; </li>
<li>		L’intervento chirurgico è meno invasivo rispetto ad altre soluzioni che necessitano l’inserimento di un maggior numero di impianti;</li>
<li>			Possibilità di abbandonare la dentiera mobile. Essendo saldamente ancorata agli impianti osteointegrati, il paziente non avrà nessun problema di dislocazione della protesi durante la masticazione.</li>
</ul>

               
               </p>
               <div className='single-img max-height500'>
                <img
                  src="./img/news/allon6/allon63.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              
            </div>


            
           
          </div>
        </section>         
        </CaseWrapper>

      </ScrollTop>

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Implanti);
