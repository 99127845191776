import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Implanti extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/news");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.3)),url('./img/news/protesimobile/mobili5.webp')" }}>
              <div className='herocaption'>
               
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 pl-md-0'>
                      <ClientTag className='date'></ClientTag>
                      <Title>Definizione</Title>
                      <TechniqueTitle>
                      LA PROTESI MOBILE  riguarda ogni tipo di apparecchio rimovibile. 
La protesi mobile è volta a sostituire una parte o l’intera arcata dentaria e può essere rimossa dal paziente allo scopo di eseguire le ordinarie manovre di igiene quotidiana. Può essere ancorate/fissate ad altri denti o, in caso di mancanza totale di elementi, appoggiate alle gengive.



                      </TechniqueTitle>
                    </div>
                  </div>
                </div>
              </div>
          </Hero>
          </Reveal>

        <section className='container' id='detailproject'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='content'>
                
              <h3 className='my-5'>PROTESI TOTALE (o Dentiera)
              </h3>
                
                <p>La protesi mobile è costituita da denti artificiali di resina o di ceramica, inseriti su una base di resina di colore simile a quello delle gengive. 
                </p>
                
                 <h4 className='my-5'>  	SCHELETRATI
                </h4> 

                Lo scheletrato è una protesi parziale mobile rinforzata da uno “scheletro” di metallo e agganciato ai denti residui.  

              <br></br> 
              <br></br>

               </div>

              <div className='content'>
              <h4> OVERDENTURE </h4><br/>
              Due o più impianti che tengono ferma una protesi mobile. 

              <br/><br/>

      <h4>	Devo portare sempre la protesi mobile?</h4>
La protesi mobile va tolta assolutamente ogni qualvolta si va a dormire e quindi almeno ogni notte. Se possibile, va tolta anche durante il giorno per qualche ora ogni tanto, questo per dare la possibilità ai tessuti di riposare e consentire alla protesi di essere igienizzata. Portare sempre la protesi è causa di un più rapido riassorbimento dell’osso sottostante e quindi di modificazioni tessutali che renderebbero la protesi instabile.  Più una protesi è instabile e più rapido e marcato sarà il riassorbimento osseo.


              </div>
              <div className='single-img max-height500'>
                <img
                  src="./img/news/protesimobile/mobili2.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>

              <div className='content'>
              <br/> <h4>	Dovrò fare dei controlli periodici?  </h4><br/>
               

              La visita di controllo è sempre importante per permettere al vostro dentista di controllare sia la salute della vostra bocca, sia la stabilità della vostra protesi mobile. E’ inoltre fondamentale controllare l’aderenza e la stabilità della protesi nel tempo, onde evitare un riassorbimento osseo e modificazioni anatomiche che andrebbero a danneggiare la qualità di vita del paziente. 
 


  <h4>Dovrò utilizzare una pasta adesiva?</h4>
  
  La protesi mobile, fatto salvo rarissime eccezioni, non necessita di adesivi per renderla stabile. La stabilità si ottiene partendo da una impronta precisa della bocca del paziente e da una precisa esecuzione delle fasi tecniche di laboratorio da parte dell’odontotecnico, per questo è importante rivolgersi a centri competenti.
  
 
  
  <h4> 	Come va pulita la protesi mobile?</h4>
  
  Esattamente come puliamo i nostri denti naturali anche la protesi mobile deve essere spazzolata dopo ogni pasto, in modo da rimuovere ogni deposito di cibo.  E’ preferibile usare uno spazzolino apposito per protesi mobili o comunque uno spazzolino con setole morbide; le setole dure potrebbero infatti danneggiarla. Sciacquarla sempre dopo il lavaggio in modo da eliminare i residui di cibo, poi con lo spazzolino bagnato applicare il prodotto detergente (dentifricio o sapone neutro), spazzolando accuratamente e con gentilezza tutte le superfici.
  
    </div>
            
           <div className="row">
            <div className='col-md-6 p-md-0'>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/protesimobile/mobili3.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/protesimobile/mobili4.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img pimg-5'>
                <img
                  src="./img/news/protesimobile/mobili1.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            </div>
            
            </div>   
            
            <div className='col-md-12'>
            <h3 className='my-5'>	Dove posso conservare la protesi mobile?</h3>
      
      <div className='content'>
             
      Quando togliamo la protesi mobile è importante conservala in un bicchiere d’acqua a temperatura ambiente (non calda) o avvolta in una salvietta inumidita. Se lasciamo la protesi all’aria o la mettiamo in acqua troppo calda potrebbe subire deformazioni e rovinarsi.
          
         

      </div>
            </div>


              <h3 className='my-5'>	Riuscirò a mangiare e a parlare bene?</h3>
            
            <div className='content'>
              Normalmente è fisiologico un po’ di tempo per abituarsi alla sensazione della protesi, così come al suo utilizzo quando si parla e si mangia. Tuttavia, già dopo qualche giorno la sensazione si allevierà, andando poi a scomparire dopo qualche settimana quando la dentiera si sarà ormai assestata nella bocca e non darà alcun tipo di problema.
            </div>
          
            </div>

            
              
              
            


            
           </div>
        
        </section>         
        </CaseWrapper>

      </ScrollTop>

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Implanti);
