import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Accomplish from '../components/Accomplish';
import Carouselteam from '../components/Carouselteam';
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Case extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/work");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    
    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "url('./img/services.jpg')" }}>
              <div className='herocaption'>
                
                <ClientTag>SERVIZI</ClientTag>
                <Title>                Lei valuta, lei sceglie, lei guadagna</Title>
                <TechniqueTitle>
                
                Sorridi Al, con molti anni di esperienza, e in collaborazione con le migliori cliniche in Albania, è a sua disposizione per soddisfare ogni sua esigenza di salute orale.

                Invia una panoramica dentale o una TAC 3D, ed entro 48 ore avrai diversi preventivi preparati dai più famosi specialisti e dalle più famose cliniche in Albania.
                
                Il nostro servizio clienti è a sua disposizione 24 ore su 7 per qualsiasi domanda che lei puo avere.

                Rimane sempre la sua scelta quale piano di trattamento le conviene di piu.


                Cogli l'opportunità di fare un trattamento odontoiatrico a basso prezzo e massima qualità. 

                Fai un investimento facile e conveniente per la sua salute.

                Nella nostra pratica, vieni sempre al primo posto.
                </TechniqueTitle>
              </div>
          </Hero>
        </Reveal>

      <br></br>
      <br></br>

        <section className='container-fluid' id='detailproject'>
          <div className='row m-2-hor' style={{paddingRight:"0"}}>
            <div className='col-md-7'>
              <h3>CURE DENTALI IN ALBANIA</h3>
              <p className='content'>
                Perché richiedere dei servizi odontoiatrici in Albania anziché nel tuo paese? 
                In Albania, tutti gli interventi di implantologia tradizionale e implantologia 
                computer guidata, chirurgia orale, protesica, estetica 
                dentale e ortodonzia vengono eseguiti nel rispetto dei protocolli odontoiatrici internazionali.
                Per ricevere un piano di cure personalizzato è necessaria inviare una panoramica dentale oppure
                TAC 3D, dove i nostri specialisti potranno effettuare esami accurati della tua situazione dentale
                e mostrarti la terapia più idonea.
              </p>
              <h3>CONSULENZA E PIANO DI CURE</h3>
              <p className='content'>
              Sono completamente gratuiti. Dopo che noi abbiamo ricevuto la sua panoramica dentale oppure TAC 3D
               (la lastra tridimensionale delle tue arcate dentali) la nostra équipe medica ti
               presenterà un piano terapeutico personalizzato spiegandoti nel dettaglio i problemi riscontrati.
              </p>
            </div>
            <div className='col-md-5 sticky'>
              <img  style={{width:"100%",marginTop:"15px"}} src="./img/services-img2.webp"/>
            </div>
            </div>
        </section>         
        </CaseWrapper>

      </ScrollTop>

      <Reveal effect="fadeInUp">
        <section className='container-fluid'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'>SERVIZI </div>
            </div>
            <div className='col-12'>
              <Carouselteam/>
            </div>
      
          </div>
        </section>
      </Reveal>


      <Reveal effect="fadeInUp">
        <section className='container-fluid'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'>
              <h3 style={{fontWeight:"500",fontSize:"1.9rem",textAlign:"center"}}>
              LA NOSTRA VISIONE È'QUELLA DI AVVERE TUTTO IN UN UNICO POSTO!
              </h3>

            <br></br>
              </div>
            </div>
            <div className='col-12' style={{display:"flex" , justifyContent:"center"}}>
            <video src="./img/smilevideo.mp4" style={{margin:"auto",width:"80%"}} muted loop  autoplay="true" />
            </div>
      
          </div>
        </section>
      </Reveal>

      


      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Case);
