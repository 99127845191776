import React, { Component } from "react";
import styles from './testimonial.module.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CustomSlide extends Component {
    render() {
      const { index, ...props } = this.props;
      return <div {...props}></div>;
    }
  }

function Testimonial(props) {

    const Services1=
    [ 
      {index:1,name:'Giuseppe', dsc:"Fantastici! Organizzazione perfetta, puntualita, ospitalita. Complimenti"},
      {index:2,name:'Domenica', dsc:"Sono molto sorpreso dalla qualità dei prodotti e dei servizi che ci hanno offerto a Tirana. Chirurgo molto professionale, ambiente sterilizzato e prezzo bassissimo. Porterà anche la mamma la prossima estate."},  
      {index:3,name:'Riccardo',  dsc:"Sono rimasto molto contento dell'accoglienza e professionalita dello staff.  All'inizio non mi fidavo, ma ora lo consiglio a tutti."},
      {index:4,name:'Giovanni',  dsc:"Buona qualita prezzo, personale cordiale e professionale, che comprende le necessita del paziente ascoltandolo e venendole incontro a tutte le richieste. Attenti ai dettagli ed esaurienti nel spiegare cosa ce da fare e cosa e' stato fatto."},       
      {index:5,name:'Monia',  dsc:"Studio gestito in modo professionale dal team. Ambiente pulito e moderno, strumenti all'avanguardia. Non posso che consigliarlo per professionalità, disponibilità e simpatia."},       
      {index:6,name:'Seloua',  dsc:"Voglio ringraziare di cuore tutto lo staff, perche finalmente ho ritrovato il mio sorriso, la mia voglia di vivere, e la mia autostima e' al top."},       
      {index:7,name:'Elisa',  dsc:"In poche parole: grazie d'esistere perche rendete la gente bella e le regalate l'autostima che mancava da tanto tempo. Grazie mille!"},       
      {index:8,name:'Irene & Massimo',  dsc:"Io e mio marito, entrambi siamo rimasti molto soddisfatti, lo staff e' stato veramente professionale. La prima volta ero molto dubbiosa e tesa pero' la dottoressa con la sua simpatia ed energia mi ha fatto dimenticare le mie paure e i miei dubbi. Sono realmente contenta. Lo siamo in effetti, io e mio marito. E ritorniamo con tanta voglia, qualora fosse necessario, e lo abbiamo raccomandato ai nostri amici e parenti."},       
      {index:9,name:'Anelya',  dsc:"Sono stato a Tirana e consiglio SorridiAl a tutti, per la loro gentilezza, cortesia, professionalita'e prezzi ottimi. Io ho tolto 11 denti, ho messo l'intera arcata superiore, impianti e corone, e tutto questo in meno di 5 giorni. Ringrazio tutti, dal dentista al suo gentilissimo staff che e' statto molto gentile con me, e che con molta simpatia e pazienza mi hanno dato pieno supporto nei momenti di bisogno. Veramente bravi. Bravi, bravi, bravi. Complimenti e grazie di cuore per tutto. P.S un ringraziamento anche alle addete telefonice, le sales agents, le quali mi hanno spiegato il tutto nei minimi particolari prima di arrivare. Grazie."},       
    ]
    
    var settings = {
        dots: true,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        responsive: [
          {
            breakpoint: 5000,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: false,
              dots: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return (

        <div className="slick slickteam">
        <Slider {...settings}>
          {Services1.map(service => (
           
           <CustomSlide
              className="itm"
              key={service.index}
              index={service.index}
            >
           
           <div>
            <div className={styles.quotes}>
    <div className={`${styles.slide} ${styles.slideshow__slideactive}`}  data-slider-slide-index="0" aria-hidden="false" tabindex="-1">
        <blockquote className="quotes-slider__text text-left">
            <span className={styles.icon}>
              <svg aria-hidden="true" focusable="false" role="presentation"  className={`${styles.icon} ${styles.icon_quote}`} viewBox="0 0 41 35">
                    <path d="M10.208 17.711h6.124v16.332H0V21.684C0 8.184 5.444.956 16.332 0v6.125c-4.083 1.14-6.124 4.414-6.124 9.82v1.766zm24.498 0h6.124v16.332H24.498V21.684C24.498 8.184 29.942.956 40.83 0v6.125c-4.083 1.14-6.124 4.414-6.124 9.82v1.766z"></path>
                </svg>
            </span>

            <div className={styles.content}>
                <p>{service.dsc}
                </p>
            </div>
            <cite>{service.name}</cite>
        </blockquote>
    </div>

</div>
        </div>

            </CustomSlide>
            
          ))
          
          }
          </Slider>
          </div>
    );
}

export default Testimonial;