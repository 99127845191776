import React from 'react';
import "../styles/pricingtable.css";


const Accomplish = () => {


 const prices= [

  {country:'GERMANY' , s1:'1000-2500', s2:'3000-4500', s3:'1500-3000', s4:'8500-13000', s5:'14000-27000', s6:'3400-6000'},
  {country:'UK' , s1:'800-1200', s2:'2400-3800', s3:'1400-2200', s4:'8000-19000', s5:'12000-23000', s6:'2700-8300'},
  {country:'ITALY' , s1:'500-600', s2:'1200-3000', s3:'1300-1700', s4:'7500-15000', s5:'9000-18000', s6:'2600-4000'},
  {country:'CROATIA' , s1:'260-310', s2:'650-870', s3:'370-450', s4:'5500-7 500', s5:'7000-10000', s6:'3000-5000'},    
  {country:'TURKEY' , s1:'250-280', s2:'600-800 ', s3:'350-400', s4:'5000-11500', s5:'7500-14000', s6:'3500-5500'}, 
  {country:'ALBANIA' , s1:'200-240', s2:'400-650', s3:'250-350', s4:'3000-4800', s5:'3500-6000', s6:'800-2500'}, 


  
  ];

  return(

<section className='container-fluid pt-0' id='listcomplish'>
<div className='row m-2-hor'>
<div className='col-12 mb-5'>
              <div className='heading'>COMPARA I PREZZI</div>
            </div>
<table class="pricing_table">
  <thead>
    <tr>
    <th scope="col"></th>
      <th scope="col">Corone Zirconio</th>
      <th scope="col">Impianto Dentale</th>
      <th scope="col">Veneers</th>
      <th scope="col">All-on-4</th>
      <th scope="col">All-on-6</th>
      <th scope="col">Invisalign</th>
    </tr>
  </thead>
  <tbody>
  {prices.map(prc=>(
     <tr key={prc.country}>
               
                <td className='country'>{prc.country}</td>
                <td className='detail'>{prc.s1}</td>
                <td className='detail'>{prc.s2}</td>
                <td className='detail'>{prc.s3}</td>
                <td className='detail'>{prc.s4}</td>
                <td className='detail'>{prc.s5}</td>
                <td className='detail'>{prc.s6}</td>
            
 </tr>
              ))}
   
  </tbody>
</table>
</div>
</section>
        );
      };
      export default Accomplish;