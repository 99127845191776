const theme = {
  colors: {
    red: "#ce2f34",
    blue: "#0a2463",
    lightBlue: "#3f88c5",
    yellow: "#ffe900",
    peach: "#eae1c0",
    lightGreen: "#39df93",
    green: "#09814a",
    darkGreen: "#2d4739",
    white: "#FFFFFF",
    black: "#090707",
    maincolor: "#ffb41d",
    logocolor:"#5aa6a6"
  }
};

export default theme;
