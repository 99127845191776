import React from 'react';
import { Link } from 'react-router-dom';

export default () => (
  <footer className='container-fluid black_more'>
  <div className='bg-footer'>
  </div>
    <div className='row m-2-hor'>
      <div className='col-md-4'>
        <div className='footer-col'>
          <div className='heading'>
            
    <img style={{width:'250px'}} src='./img/logowhite.png' alt='bg-footer'/>
            {/* <h2>Chi siamo:</h2> */}
          </div>
          <div className='content'>
             <p>
             Sorridial offre trattamenti dentali di alta qualità a prezzi convenienti in Albania.
             Ci dedichiamo all'eccellenza nell'odontoiatria e nel servizio clienti. 
             </p>
          </div>
          <div className='link-call' onClick={()=> window.open("mailto:info@sorridial.com", "_self")}>info@sorridial.com</div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
          Link utile

          </div>
          <div className='content'>
             <div className='link'><Link to='/home'>Home</Link></div>
             <div className='link'><Link to='/about'>Chi Siamo</Link></div>
             <div className='link'><Link to='/albania'>Perche l'Albania</Link></div>
             {/* <div className='link'><Link to='/contact'>Come Funziona</Link></div> */}
          </div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
          Link utile
          </div>
          <div className='content'>
          {/* <div className='link'><Link to='/home'>Recensioni</Link></div> */}
             {/* <div className='link'><Link to='/contact'>Articoli</Link></div> */}
             {/* <div className='link'><Link to='/contact'>Offerte</Link></div> */}
             {/* <div className='link'><Link to='/contact'>Covid-19</Link></div> */}
             <div className='link'><Link to='/servizi'>Servizi</Link></div>
             <div className='link'><Link to='/galleria'>Galleria</Link></div>
             <div className='link'><Link to='/contact'>Contatto</Link></div>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='footer-col'>
          <div className='heading'>
          Seguici
          </div>
          <div className='content'>
            <p>
              Rimani aggiornato con le nostre ultime offerte e tutte le informazioni di cui hai bisogno sulla tua salute orale.
              </p>
            <a href='https://www.facebook.com/dentalturismoAlbania/' className='socialicon'>
            <span className="shine"></span>
              <i className="fa fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/sorridi_al/" className='socialicon'>
            <span className="shine"></span>
              <i className="fa  fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className='subfooter'>
      <div className='row m-2-hor'>
        <div className='col-md-6'>
          <div className='content'>© Sorridial 2021 - Tutti i diritti riservati.</div>
        </div>
      </div>
    </div>
  </footer>
);
