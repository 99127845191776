import React from "react";
import { withRouter } from "react-router-dom";
import emailjs from 'emailjs-com';
import Reveal from 'react-reveal/Reveal';
import ContactForm from '../components/ContactForm/ContactForm';
import SimpleMap from '../components/Map';
import Footer from '../components/Footer';

import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
  

const Contact = ({ history }) => {

    function sendEmail(e) {
    const success = document.getElementById("success");
    const button = document.getElementById("buttonsent");
    const failed = document.getElementById("failed");
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_csfdEZiA', e.target, 'user_zu7p2b3lDibMCDutH5hif')
      .then((result) => {
          console.log(result.text);
          success.classList.add('show');
          button.classList.add('show');
          failed.classList.remove('show');
      }, (error) => {
          console.log(error.text);
          failed.classList.add('show');
      });
  }  

  return (
    <div>
      <Efect/>
      <Efect1/>
      <Efect2/>

      

      
      <Reveal effect="fadeIn">
      <section className='header__baner jumbotron imgtop'>
          <h3>CONTATTACI</h3>
        	<img src="./img/contact.jpg" className="img-fluid" alt="Albania" />
        </section>
       </Reveal>


     

      <Reveal effect="fadeInUp">
       <section className='container-fluid'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <h1 style={{textTransform:"uppercase"}}>Contattaci per avere <br/><span className='color'>il sorriso </span> che hai sempre voluto!</h1>
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
       <section className='container-fluid'>
          <div className='row m-2-hor'>
            <div className='col-md-5'>
            <div className="text-side">
              <h3 className="heading">Preventivo gratuito e senza impegno
 </h3>
              <p>
                
              Contattaci telefonicamente al: + 355 69 40 48 535 o via e-mail a info@sorridial.com per organizzare una prima visita gratuita e ricevere un piano di cura personalizzato e un preventivo dettagliato o inviaci una panoramica dentale per un preventivo di massima.
              </p>
              <div className='address'>
                <div className='heading'>Il Nostro Ufficio</div>
                <div className='list'>
                  <i className="fa fa-map-marker"></i>
                  Rruga Hasan Alla, Tirana, 1060 Albania
                </div>
                <div className='list'>
                  <i className="fa fa-envelope-o"></i>
                  <a href='mailto:info@sorridial.com' target='_blank' rel='noopener noreferrer'>
                    info@sorridial.com
                  </a>
                </div>
                <div className='list'>
                  <i className="fa fa-phone"></i>
                  +355 69 40 48 535
                </div>
                </div>
              </div>
            </div>

            <div className='col-md-7'>
            <ContactForm />
          </div>
          </div>


          <div className="row mt-5">

          <div className='col-12'>
              <SimpleMap/>
            </div>
            </div>
       
       </section>
      </Reveal>

      <Reveal effect="fadeIn">
        <section style={{maxHeight:'70vh'}} className='jumbotron imgtop'>
          <h3 style={{position:"absolute",bottom:"3%",right:"3%",color:"white",fontSize:"5rem"}}></h3>
        	<img
	          src="./img/tiranaa.png"
	          className="img-fluid"
	          alt="Albania"
	        />
        </section>
       </Reveal>

      

      <Footer />

    </div>
    
  );
};

export default withRouter(Contact);
