import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Implanti extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/news");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.3)),url('./img/news/corone/corone9.webp')" }}>
              <div className='herocaption'>
               
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 pl-md-0'>
                      <ClientTag className='date'></ClientTag>
                      <Title>Definizione</Title>
                      <TechniqueTitle>
                     

                      </TechniqueTitle>
                    </div>
                  </div>
                </div>
              </div>
          </Hero>
          </Reveal>

        <section className='container' id='detailproject'>
          <div className='row'>
            <div className='col-md-12'>
              <p className='content'>
                
              <h3 className='my-5'>PERCHÉ LE CORONE IN ZIRCONIO CERAMICA SONO LA MIGLIORE SOLUZIONE PER LA SOSTITUZIONE DEI DENTI?
              </h3>
                
                <p>Dopo l’impianto, la soluzione più comunemente utilizzata per compensare i denti mancanti sono le corone e ponti in ceramica, le quali sono esteticamente e funzionalmente piu’ simili ai denti naturali. Fino a poco tempo fa, le corone in metallo ceramica erano la soluzione migliore, ma negli ultimi anni hanno dominato esteticamente superiori, cosiddette non metalliche corone in ceramica, specialmente le corone in zirconio ceramica. 
 </p>
                
                


                 <h4 className='my-5'> CORONE IN METALLO-CERAMICA
                </h4> 



<br></br>
La fondazione della corona in metallo-ceramica è il metallo, su cui viene cotta ceramica. La base in metallo assicura la fermezza della corona, mentre ceramica le fornisce con ottima estetica. Per produrre un colore più naturale per il dente, è necessario prima coprire il metallo con un materiale che impedisca illuminazione del suo colore grigio di sotto, in modo da “mascherarlo”. Solo dopo che è stata applicata, è possibile “sistemare” la ceramica secondo i colori come richiede ogni caso individuale. Il grande vantaggio di queste corone è che è possibile individualizzare completamente ciascuna, cioè regolare la situazione per adattarsi al meglio ai denti.  
<br></br> <br></br>

È anche possibile imitare i dettagli più fini come punti marroni o bianchi, strisce piccoli o sporgenze, quindi tutti i dettagli che troviamo sui denti naturali.
Fino a poco tempo fa, queste corone rappresentavano il culmine della cura estetica dei denti, ma con il progresso della tecnologia ottenemmo una soluzione esteticamente superiore, cosìdette non metalliche corone in ceramica.
 


               
               </p>
              
  
              
              <div className='single-img max-height500'>
                <img
                  src="./img/news/corone/corone1.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>

              
            
              <h3 className='my-5'>LA PREPARAZIONE DELLE CORONE DENTALI</h3>
              Ci sono alcune regole per la preparazione dei denti per ogni tipo di corona. Dopo che il dente è stato limato, viene preso l’impronte che viene quindi inviato al laboratorio dove i tecnici cominciano con la preparazione. Il lavoro si torna alla clinica, e se tutti sono soddisfatti, il lavoro è cementato.<br/><br/>
              Per tutte le corone è molto importante fare una produzione precisa in modo che la relazione con il dente a cui è posizionata e la gengiva dentale fosse perfetta. La corona deve essere posizionata precisamente sul dente limato. Il bordo della corona dovrebbe andare un po ‘al di sotto della gengiva, e dovrebbe sempre seguire il contorno perche altrimenti provocherà infiammazione e recessione gengivale. La corona dovrebbe essere in contatto con i denti adiacenti in modo di prevenire che il cibo e’ incastrato tra i denti.<br/><br/>
              Oltre alla lavorazione precisa per la durevolezza del lavoro, la qualita’ dell’igiene orale è molto importante. Naturalmente, sono anche necessari controlli regolari in consultazione con il dentista.
          <p className='content'>
                 <br></br>
              
              
             

          </p>
          <p className='content'>
              <br/> <h4>VENEERS EMAX ( FACETTE IN CERAMICA)   </h4><br/>
              Sognate un bel sorriso bianco? Desiderate che sia splendente come il sorriso delle celebrità, degli attori famosi e dei personaggi TV, o semplicemente non vi piace la forma, il colore o la posizione dei propri denti? 
<br/><br/>
Lo sapevate che l’80 percento delle celebrità ha subito interventi di chirurgia estetica e quel bel sorriso lo devono alle faccette in ceramica – Veneers? <br/><br/> 
Le faccette di ceramica – Veneers, fatte in Emax ceramica sono di spessore da 0.3 mm a 0.5 mm e vengono incollate alla parte anteriore dei denti per migliorare l’aspetto estetico, correggere erosioni, danni, colore o la posizione del dente.<br/><br/>
Sono sottili, bianche e traslucide, in questo modo mimano perfettamente i denti naturali. Le faccette si applicano al dente con la tecnica adesiva la quale gli da una lunga durata ed eccezionale resistenza. Possono essere utilizzate sui denti frontali e posteriori.<br/>
<h6>Le faccette possono essere utilizzate per correggere un’ampia gamma di problemi dentali, come:</h6>
<ul>
  <li>
  	denti che sono colorati e non possono essere sbiancati con lo sbiancamento
  </li>
  <li>
  	denti scheggiati o consumati
  </li>
  <li>
  	denti storti o deformi
  </li>
  <li>
  spazi irregolari o un ampio spazio tra i denti anteriori superiori
  </li>
</ul>
La procedura di applicazione delle faccette inizia con un’impronta dentale e la creazione dell’impronta “wax-up” per vedere il futuro aspetto dei denti ed evitare l’eccesiva rimozione dello smalto dentale.<br/><br/>
Poi si elimina uno strato di smalto, da 0.3 mm a 0.5 mm e si prende nuovamente l’impronta che sarà portata nel laboratorio odontotecnico dove le faccette verranno create. Le faccette vanno testate per assicurarsi che aderiscano bene e che la forma ottenuta sia quella desiderata.<br/> <br/>
Soltanto quando il dottore e il paziente sono completamente soddisfatti del risultato ottenuto saranno cementate sui denti. Le faccette in ceramica, rispettando i controlli regolari possono durare a lungo, in alcuni casi più delle corone. Il motivo è che la faccetta si posa sulla parte anteriore del dente (sullo smalto) a differenza della corona la quale deve essere posizionata all’interno del dente (dentin).<br/><br/>
Sono importanti i controlli regolari per togliere i depositi di tartaro e placca e così prolungare la longevità delle faccette.<br/><br/>



              </p>
        </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/corone/corone5.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/corone/corone6.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img pimg-5'>
                <img
                  src="./img/news/veneers/veneers4.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div> 
            </div>
            <div className='col-md-12'>
              
              
            </div>


            
           
          </div>
        </section>         
        </CaseWrapper>

      </ScrollTop>

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Implanti);
