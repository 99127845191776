import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Implanti extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/news");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.3)),url('./img/news/invisalign/ivisalign6.webp')" }}>
              <div className='herocaption'>
               
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 pl-md-0'>
                      <ClientTag className='date'></ClientTag>
                      <Title>Definizione</Title>
                      <TechniqueTitle>
                      È un’innovazione nell’ortodonzia, il ramo dell’odontoiatria che corregge i vari difetti dentali. Finalmente oggi puoi dire addio al classico apparecchio dei denti, metallico e antiestetico.
“Invisalign” è un termine formato dalle parole inglesi invisible e align: cioè allineamento (dei denti) invisibile.
Sì, Invisalign è quindi un apparecchio invisibile per i denti, perché è trasparente e si mimetizza con i tuoi denti.


                      </TechniqueTitle>
                    </div>
                  </div>
                </div>
              </div>
          </Hero>
          </Reveal>

        <section className='container' id='detailproject'>
          <div className='row'>
            <div className='col-md-12'>
              <p className='content'>
                
              <h3 className='my-5'>Come funziona Invisalign?
              </h3>
                
                <p>Prima di tutto il dentista farà una radiografia dei tuoi denti, perché gli allineatori sono personalizzati e vanno realizzati appositamente per la tua dentatura. Inizialmente ti sarà consegnato un astuccio con 1 set di allineatori realizzati in termoplastica trasparente, che dovrai indossare per 20-22 ore al giorno.<br/><br/>Dopo una o due settimane dovrai tornare per avere un nuovo set. Ogni set di allineatori sposta i tuoi denti gradualmente, facendoli pian piano tornare alla loro posizione ideale. 
 </p>
                
                


                 <h4 className='my-5'>  È doloroso?
                </h4> 




Assolutamente no. In generale si può provare un normale e temporaneo fastidio all’inizio di ogni nuovo set, che scompare comunque in uno o due giorni. Ma questo disagio è un chiaro segno che Invisalign sta raddrizzando i tuoi denti. La plastica, inoltre, è liscia e non irrita né denti né gengive.  
<br></br> <br></br>



               
               </p>
              <p className='content'>
              <h4> Devo indossare sempre gli allineatori? </h4><br/>
              È consigliabile toglierli soltanto durante i pasti, per pulirli e quando ti lavi i denti. Invisalign funziona indossando gli allineatori, quindi è bene che tu non li tolga neanche di notte. Come ti abbiamo accennato, quando mangi, ti lavi i denti o devi pulire gli allineatori, naturalmente devi toglierli. O anche in casi eccezionali come cerimonie o colloqui di lavoro. 
<br/><br/>
<h4>È davvero invisibile Invisalign?</h4><br/>
Sì. A differenza del classico apparecchio metallico per i denti, gli allineatori di Invisalign sono praticamente invisibili, non hanno fili metallici sporgenti. Sono sottili e nessuno si accorgerà che li indossi.
<br/><br/>

              </p>
              <div className='single-img max-height500'>
                <img
                  src="./img/news/invisalign/invisalign1.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>

              <p className='content'>
              <br/> <h4>Quanto dura il trattamento con Invisalign?   </h4><br/>
               

La durata del trattamenti dipende dal problema da correggere, dalle condizioni dei tuoi denti, ma anche dalla frequenza in cui indosserai gli allineatori. Si raccomandano almeno 20-22 ore al giorno e va da sé che se qualcuno li indossa per un paio d’ore soltanto, i tempi si allungheranno inevitabilmente. 
 
  <br/> <br/> <h4>Ho dovuto portare l’apparecchio in passato, ma i miei denti non si sono spostati bene. Posso usare Invisalign?</h4>
  <br/>  Il trattamento Invisalign può correggere questo problema. Molti pazienti che prima avevano il normale apparecchio metallico sono poi passati a Invisalign.
  <br/> <br/> <h4>  A chi è consigliato Invisalign? È un trattamento per tutti?</h4>
  <br/>Sì, è un trattamento adatto ad adolescenti e adulti. La tecnologia Invisalign può correggere quasi tutti i problemi più comuni di allineamento dei denti, dai più semplici a quelli complessi.
  
              </p>
            
              <h3 className='my-5'>Posso fumare, bere alcol o masticare gomme con gli allineatori?</h3>
      
          <p className='content'>
                 
              <ul>
                <li>
                •	È consigliabile non fumare, poiché il fumo può macchiare la plastica degli allineatori. 
                </li> <br></br>
                <li>•	Bere alcol (e in genere bevande zuccherate) in quantità moderate, perché contiene zuccheri che possono restare fra denti e allineatori.
 </li>
                <br></br>
                <li>•	Niente gomme da masticare, perché si attaccano agli allineatori.
</li>
                <br></br>
                
          
              </ul>
              
             

          </p>
        </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/invisalign/invisalign4.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/news/invisalign/invisalign3.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img pimg-5'>
                <img
                  src="./img/news/invisalign/invisalign2.webp"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
            </div>
            <div className='col-md-12'>
              <h3 className='my-5'>Qual è il modo migliore per pulire gli allineatori?</h3>
              <p className='content'>
              Puoi usare il kit di pulizia Invisalign. Oppure pulire gli allineatori spazzolandoli e risciacquandoli con acqua tiepida (mai calda).
</p>
            </div>

            <div className='col-md-12'>
              <h3 className='my-5'>Ho le faccette estetiche. Posso usare lo stesso Invisalign?</h3>
              <p className='content'>
              Certamente, il trattamento con Invisalign si può avere anche se hai le faccette dentali, le normali otturazioni o le corone. 
              </p>
              
              
            </div>


            
           
          </div>
        </section>         
        </CaseWrapper>

      </ScrollTop>

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Implanti);
