import React from 'react';

export default () => (
  <section className='container-fluid'>
          <div className='row m-2-hor'>
            
            <div className='col-md-6 pt-5'>
              <div className='col-home'>
                <div className='thumb'>
                  <img
                      src="./img/home.webp"
                      className="img-fluid"
                      alt="#"
                    />
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='dflex-center'>
                <div className='col-home mt-md-0 mt-5'>
                  <div className='heading'>ODONTOIATRIA PREMIUM A PREZZI CONVENIENTI</div>
                  <div className='content'>
                  Con la nostra pluriennale esperienza, lo staff di Sorridial, si impegnia ad offrire l’odontoiatria moderna, la piu’ recente, offrendo il massimo per i nostri clienti. Il nostro staff e’ qualificato e specializzato in diversi ambiti dentistici, come l’implantologia, la chirurgia orale e tanto altro, per darti la possibilita’ di avere tutte le soluzioni necessarie in unico posto. La nostra clinica e il nostro personale e’ in continua crescita, per poter fornire un servizio impeccabile dalla A alla Z.
                  </div>
                  <ul className='list-home'>
                    <li>Pienamente incentrati sul paziente</li>
                    <li>Tecnologie all’avanguardia</li>
                    <li>Impegno massimo per la sua sicurezza</li>
                    <li>Personale cordiale ed amichevole</li>
                  </ul>
                </div>
              </div>
            </div>
            
          </div>
        </section>
);
