import React from 'react';

export default () => (
  <section className='container-fluid'>
          <div className='row m-2-hor'>
            
            <div className='col-md-6 pt-5'>
              <div className='col-home'>
                <div className='thumb' style={{width:"100%"}}>
                  <img style={{width:"90%"}}
                      src="./img/iva.jpg"
                      className="img-fluid"
                      alt="#"
                    />
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='dflex-center'>
                <div className='col-home mt-md-0 mt-5'>
                  <div className='heading'>I TRATTAMENTI ODONTOIATRICI SONO ESCLUSI DALL'IVA IN ALBANIA!</div>
                  <div className='content'>
                  Negli ultimi 5 anni, 8 milioni di italiani hanno scelto l'Albania come prima destinazione per le vacanze estive 
                  dove hanno scoperto prezzi bassissimi delle cure dentali e si sono resi conto che in Albania possono avere sia 
                  le cure dentali che delle vacanze indimenticabili allo stesso tempo, pagando il 
                  70% in meno di quanto pagherebbero in Italia solo per le cure dentistiche, senza vacanze rilassanti.
                      
                     <br></br>
                        <br></br>

                        Ciò che fa la differenza tra l'Albania e gli altri paesi che offrono turismo dentale è il fatto che tutti gli 
                        stranieri sono esclusi dalle tasse Iva (20%) per tutti i tipi di cure dentistiche in Albania. Tutti gli stranieri 
                        hanno la possibilità di pagare il 20% in meno rispetto ai cittadini albanesi per qualsiasi tipo di cura dentale. 
                        Tirana, essendo la capitale albanese e sede delle maggiori università del paese e anche 
                        la città vicina all'aeroporto, divenne presto un importante centro di turismo dentale in Albania.
                  </div>
                 
                </div>
              </div>
            </div>
            
          </div>
        </section>
);
