import React from 'react';

export default () => (
  <section className='container-fluid bggray py-1'>
        <div className='row m-2-hor'>

          <div className='col-lg-3 col-md-6'>
            <div className='featurehome'>
              <div className='img'>
               1
              </div>
              <div className='text'>
              Informati
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-6'>
            <div className='featurehome'>
              <div className='img'>
                2
              </div>
              <div className='text'>
              Controlla i trattamenti 
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-6'>
            <div className='featurehome'>
              <div className='img'>
             3
              </div>
              <div className='text'>
              Ottieni preventivo gratuito
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-6'>
            <div className='featurehome last'>
              <div className='img'>
                4
              </div>
              <div className='text'>
              Scegli l'opzione migliore
              </div>
            </div>
          </div>

        </div>
      </section>
);
