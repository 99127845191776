import React from "react";
import Gallery from "react-photo-gallery";
import { photos } from "./Photos";

const openFullScreeen = event => {
  const lmao = event.target.getAttribute("src");
  console.log(lmao);
  photos.forEach(item => {
    if (lmao === item.src) {
      var fullRes = item.fullres;
      console.log("Found");
      window.open(fullRes, "_blank");
    } else {
      console.log("NOt Found");
    }
  });
};

const GalleryMasonry = () => (
  <div className="Gallery">
    <Gallery
      photos={photos}
      key={photos.key}
      direction={"column"}
      margin={10}
      onClick={openFullScreeen}
    />
  </div>
);

export default GalleryMasonry;
