import React from 'react';

export default (props) => (
  <section className='container-fluid'>
            

 
 {props.position === "left" ? 
 (

  
  <div className='row m-2-hor'>

  <div className='col-md-6'>
  <div className='col-home'>
    <div className='thumb' style={{width:"100%"}}>
      <img style={{width:"90%"}}
          src={props.imgsrc}
          className="img-fluid"
          alt="#"
        />
    </div>
  </div>
  </div>
  
  <div className='col-md-6'>
  <div className='dflex-center'>
    <div className='col-home mt-md-0 mt-5'>
      <div className='heading'>{props.title1}</div>
      <div className='content'>
      {props.dsc1}
          
         <br></br>
            <br></br>
            <div className='heading'>{props.title2}</div>
            {props.dsc2}
      </div>
     
    </div>
  </div>
  </div>
   </div>  
 )
 
 
 
 : (
  <div className='row m-2-hor'>

  <div className='col-md-6'>
<div className='dflex-center'>
<div className='col-home mt-md-0 mt-5'>
  <div className='heading'>{props.title1}</div>
  <div className='content'>
  {props.dsc1}
      
     <br></br>
        <br></br>
        <div className='heading'>{props.title2}</div>
        {props.dsc2}
  </div>
 
</div>
</div>
</div>

<div className='col-md-6 pt-5'>
<div className='col-home'>
<div className='thumb' style={{width:"100%"}}>
  <img style={{width:"90%"}}
      src={props.imgsrc}
      className="img-fluid"
      alt="#"
    />
</div>
</div>
</div>


</div>      
 )}

   </section>
);
