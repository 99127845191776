import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
  
} from "../styles/Case.styles";
import GalleryMasonry from "../components/Gallery/GalleryMasonry";
import ImageWithCaption from "../components/Gallery/GalleryMasonry.js";
import "../components/Gallery/styles.css";
import { photos } from "../components/Gallery/Photos.js";
import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Accomplish from '../components/Accomplish';
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";


const imageRenderer = ({ index, left, top, key, photo }) => (
  <ImageWithCaption
    key={key}
    margin={"2px"}
    index={index}
    photo={photo}
    left={left}
    top={top}
  />
);


export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Case extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/work");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {

    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
     
      <Reveal effect="fadeIn">
      <section className='header__baner jumbotron imgtop'>
          <h3>NOSTRI LAVORI</h3>
        	<img src="./img/bg-3.jpg" className="img-fluid" alt="Albania" />
        </section>
       </Reveal>
       
       
       
       <section className='container-fluid' id='detailproject'>
          <div className='row m-2-hor'>
            <div className='col-md-12'>
       
            
            <GalleryMasonry />
            
         
            </div>

          </div>
        </section>         
     

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Case);
